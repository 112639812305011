import { Field } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../../theme/breakpoints'
import CustomLabel from './CustomLabel'

export interface ITextAreaProps {
  id: string
  label?: string
  placeholder?: string
  type?: string
  hint?: string
  rows?: number
}

const TextArea: React.FC<ITextAreaProps> = ({ id, label, placeholder, rows }) => {
  return (
    <Container>
      {label && <CustomLabel htmlFor={id}>{label}</CustomLabel>}
      <CustomInput id={id} name={id} component='textarea' placeholder={placeholder} rows={rows} />
    </Container>
  )
}

export default TextArea

const Container = styled.div`
  min-width: 25rem;

  @media ${breakpoints.mobileL} {
    min-width: 30rem;
  }
`

const CustomInput = styled(Field)`
  border: solid 1px #e6e6e6;
  width: 100%;
  padding: 1.8rem;
  border-radius: 0.4rem;
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: ${({ theme }) => theme.size.small};
  resize: vertical;

  ::placeholder {
    font-weight: ${({ theme }) => theme.weight.normal};
    font-size: ${({ theme }) => theme.size.small};
    color: ${({ theme }) => theme.color.grey};
  }
`
