export interface IUserLocationProps {
  city: string
  cityCode: string
  country: string
  latitude: number
  longitude: number
}

class UserLocation {
  city: string
  cityCode: string
  country: string
  latitude: number
  longitude: number


  constructor({ city, cityCode, country, latitude, longitude }: IUserLocationProps) {
    this.city = city
    this.cityCode = cityCode
    this.country = country
    this.latitude = latitude
    this.longitude = longitude
  }
}

export default UserLocation
