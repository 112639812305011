import React from 'react'
import styled from 'styled-components'
import { useField, useFormikContext } from 'formik'
import { SingleValue } from 'react-select'
import AsyncSelect from 'react-select/async'
import { breakpoints } from '../../../theme/breakpoints'
import CustomLabel from './CustomLabel'
import { ErrorMessageWrapper } from './index'
import { IAddressResult } from '../../../core/domain/providers/IGeolocationProvider'

export interface LocationOption {
  label: string
  value: IAddressResult
}

export interface ILocationInputProps {
  id: string
  label?: string
  placeholder?: string,
  value?: LocationOption
  error?: string | null
  selectOptionLoader: (inputValue: string) => Promise<Array<LocationOption>>
}


const LocationInput: React.FC<ILocationInputProps> = ({ id, value, label, error, placeholder, selectOptionLoader }) => {

  const {
    setFieldValue
  } = useFormikContext()
  const [field] = useField({ name: id })
  const handleOnChange = (option: SingleValue<LocationOption>) => {
    if (option) {
      setFieldValue(`${field.name}.city`, option.value.city)
      setFieldValue(`${field.name}.cityCode`, option.value.cityCode)
      setFieldValue(`${field.name}.latitude`, option.value.latitude)
      setFieldValue(`${field.name}.longitude`, option.value.longitude)
      setFieldValue(`${field.name}.country`, option.value.country)

    }
  }

  return (
    <Container>
      {label && <CustomLabel htmlFor={id}>{label}</CustomLabel>}
      <AsyncSelect
        value={value} inputId={id}
        instanceId={`instance-${id}`}
        classNamePrefix='react-select'
        loadOptions={selectOptionLoader}
        placeholder={placeholder}
        onChange={handleOnChange} />
      <ErrorMessageWrapper isVisible={error !== undefined}>{error}</ErrorMessageWrapper>
    </Container>
  )
}


const Container = styled.div`
  min-width: 25rem;

  @media ${breakpoints.mobileL} {
    min-width: 30rem;
  }

  .react-select__control {
    padding: 0.7rem 0.7rem;
    box-shadow: unset;
    border: 1px solid #e6e6e6;
    border-radius: 0.4rem;

    :hover {
      border: 1px solid #e6e6e6;
    }
  }

  .react-select__control--is-focused,
  .react-select__control--menu-is-open {

  }

  .react-select__value-container {
    color: ${({ theme }) => theme.color.black};
    font-weight: ${({ theme }) => theme.weight.normal};
    font-size: ${({ theme }) => theme.size.small};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    display: none;
  }


  .react-select__option {
    color: ${({ theme }) => theme.color.black};
    font-weight: ${({ theme }) => theme.weight.normal};
    font-size: ${({ theme }) => theme.size.small};
  }
`

export default LocationInput
