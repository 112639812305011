import React from 'react'
import Image from 'next/image'
import { cloudinarySafeLoader } from '../../../core/application/utils/imageUtils'

export interface IAvatarImageProps {
  src: string
  alt?: string
  width?: number
  height?: number
  quality?: number
}

const CircleAvatarImage: React.VFC<IAvatarImageProps> = ({ src, alt, width, height, quality }) => {
  return (
    <Image
      src={src}
      loader={cloudinarySafeLoader}
      decoding='async'
      width={width}
      height={height}
      quality={quality}
      alt={alt}
    >
    </Image>
  )
}

export default CircleAvatarImage
