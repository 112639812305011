import React, { useState } from 'react'
import MyToast from '../layout/MyToast'
import { ToastMode } from '../../core/application/types'
import { OpenToastParams, ToastContext } from '../../core/application/contexts/ToastContext'

const ToastManager: React.FC = ({ children }) => {
  const [title, setTitle] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [mode, setMode] = useState<ToastMode>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleDisplayToast = ({ title, description, mode }: OpenToastParams) => {
    setTitle(title)
    setDescription(description)
    setMode(mode)
    setIsOpen(true)
  }

  const handleCloseToast = () => {
    setIsOpen(false)
  }

  const handleOnChange = (value: boolean) => {
    if (!value) {
      handleCloseToast()
    }
  }

  return (
    <ToastContext.Provider
      value={{
        displayToast: handleDisplayToast,
        closeToast: handleCloseToast
      }}>
      {children}
      <MyToast
        open={isOpen}
        title={title}
        mode={mode}
        description={description}
        onOpenChange={handleOnChange} />
    </ToastContext.Provider>
  )
}

export default ToastManager
