export default {
  REGISTER: '/auth/register',
  ME: '/me',
  UPDATE_EMAIL: '/me/update-email',
  USERS: '/users',
  GAMES: '/games',
  SEARCH: '/games/search',
  USER_GAMES: '/usergames',
  WISH: '/wishes',
  OFFERS: '/offers',
  FILE_UPLOAD: '/upload',
  NOTIFICATIONS: '/notifications',
  GLOBAL_STATS: '/stats/global',
  CHANNELS: '/channels'
}
