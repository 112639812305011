import { useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { IntlProvider } from 'react-intl'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Head from 'next/head'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreview } from '@prismicio/next'
import styled, { ThemeProvider } from 'styled-components'
import localesFr from '../locales/fr.json'
import localesEn from '../locales/en.json'
import '../public/css/fonts.css'
import '../public/css/minireset.css'
import { commonTheme } from '../theme/Theme'
import { Modal } from '../core/application/types'
import { AppProviderContext, ErrorModalContext, ModalContext } from '../core/application/contexts'
import { Footer, GlobalStyle, ModalManager, Navbar } from '../components/layout'
import { createDependencies } from '../core/application/dependencies'
import MobileNavbar from '../components/layout/MobileNavbar'
import { breakpoints } from '../theme/breakpoints'
import { linkResolver, repositoryName } from '../prismic'
import { NotificationManager, UserManager, ToastManager } from '../components/manager'


const messages: Record<string, any> = {
  fr: localesFr,
  en: localesEn
}


const dependencies = createDependencies()
const queryClient = new QueryClient()

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  const currentLocale = router.locale || 'en'
  const [currentModal, setCurrentModal] = useState<Modal>()
  const [errorModalMessage, setErrorModalMessage] = useState<string | undefined>()
  const [errorModal, setErrorModal] = useState<boolean>(false)
  const [isAuth, setIsAuth] = useState<boolean>(false)


  return (
    <PrismicProvider
      linkResolver={linkResolver}
      internalLinkComponent={({ href, children, ...props }) => (
        <Link href={href}>
          <a {...props}>
            {children}
          </a>
        </Link>
      )}
    >
      <PrismicPreview repositoryName={repositoryName}>
        <Head>
          <title>Swappy</title>
        </Head>
        <IntlProvider
          messages={messages[currentLocale]}
          locale={currentLocale}
          defaultLocale='en'
        >
          {/*// @ts-ignore */}
          <ThemeProvider theme={commonTheme}>
            <GlobalStyle />
            <ToastManager>
              <QueryClientProvider client={queryClient}>
                <AppProviderContext.Provider value={{
                  authProvider: dependencies.authProvider,
                  swappyProvider: dependencies.swappyProvider,
                  geolocationProvider: dependencies.geolocationProvider,
                  isAuth,
                  setIsAuth
                }}>
                  <UserManager>
                    <NotificationManager>
                      <ErrorModalContext.Provider
                        value={{
                          setIsOpen: setErrorModal,
                          isOpen: errorModal,
                          setErrorModalMessage: (error: string) => {
                            setErrorModalMessage(error)
                            setErrorModal(true)
                          },
                          errorModalMessage
                        }}>
                        <ModalContext.Provider value={{
                          currentModal,
                          setCurrentModal,
                          closeCurrentModal: () => setCurrentModal(undefined)
                        }}>
                          <NavbarWrapper>
                            <Navbar />
                            <MobileNavbar />
                          </NavbarWrapper>
                          {/*// @ts-ignore */}
                          <Component {...pageProps} />
                          <ModalManager />
                          <Footer />
                        </ModalContext.Provider>
                      </ErrorModalContext.Provider>
                    </NotificationManager>
                  </UserManager>
                </AppProviderContext.Provider>
              </QueryClientProvider>
            </ToastManager>
          </ThemeProvider>
        </IntlProvider>
      </PrismicPreview>
    </PrismicProvider>
  )
}

const NavbarWrapper = styled.div`
  #navbar {
    display: none;
  }

  #mobile-navbar {
    display: flex;
  }

  @media ${breakpoints.tablet} {
    #navbar {
      display: flex;
    }

    #mobile-navbar {
      display: none;
    }
  }
`

export default MyApp
