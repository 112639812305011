export enum OfferActionCode {
  SEND_OFFER = 'SEND_OFFER',
  ACCEPT_OFFER = 'ACCEPT_OFFER',
  REFUSE_OFFER = 'REFUSE_OFFER',
  CONFIRM_EXCHANGE = 'CONFIRM_EXCHANGE',
  CANCEL_OFFER = 'CANCEL_OFFER',
  CANCEL_EXCHANGE = 'CANCEL_EXCHANGE',
  UNKNOWN = 'UNKNOWN'
}

const parseOfferActionCode = (value: string): OfferActionCode => {
  switch (value) {
    case 'SEND_OFFER':
      return OfferActionCode.SEND_OFFER
    case 'ACCEPT_OFFER':
      return OfferActionCode.ACCEPT_OFFER
    case 'REFUSE_OFFER':
      return OfferActionCode.REFUSE_OFFER
    case 'CONFIRM_EXCHANGE':
      return OfferActionCode.CONFIRM_EXCHANGE
    case 'CANCEL_OFFER':
      return OfferActionCode.CANCEL_OFFER
    case 'CANCEL_EXCHANGE':
      return OfferActionCode.CANCEL_EXCHANGE
    default:
      return OfferActionCode.UNKNOWN
  }
}

const isValidOfferActionCode = (value: string): value is OfferActionCode => {
  return Object.values(OfferActionCode).includes(value as OfferActionCode)
}

export { parseOfferActionCode, isValidOfferActionCode }
