import styled from 'styled-components'

export default styled.div`
  padding: 2.8rem;
  border-top: solid 1px ${({ theme }) => theme.color.lightGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
