import React from 'react'

export interface INotificationContext {
  notificationCount: number
  setNotificationCount: (value: number) => void
}

export const NotificationContext = React.createContext<INotificationContext>({
  notificationCount: 0,
  setNotificationCount: () => {
    console.warn(
      'setNotificationCount method not implemented in current context'
    )
  }
})

export const useNotificationContext = ():INotificationContext => React.useContext(NotificationContext)
