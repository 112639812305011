import { ImageLoader } from 'next/dist/client/image'

const createCloudinaryThumb = (src: string, width: number, quality = 80): string => {
  const thumbSize = width * 2
  const assetId = src.split('/upload/')[1]
  return `https://res.cloudinary.com/sahara36/image/upload/dpr_2.0,c_thumb,f_auto,g_face,h_${thumbSize},q_${quality},w_${thumbSize}/${assetId}`
}

const cloudinarySafeLoader: ImageLoader = ({ src, width, quality }) => {
  if (src.includes('res.cloudinary.com')) {
    return createCloudinaryThumb(src, width, quality)
  }
  return src
}

export {
  createCloudinaryThumb,
  cloudinarySafeLoader
}
