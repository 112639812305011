export interface IFirebaseError {
  message: string
  code: string
}

const isFirebaseError = (error: any): error is IFirebaseError => {
  return (typeof error === 'object') && (error.code !== undefined) && (error.message !== undefined)
}

export  {
  isFirebaseError
}
