import styled from 'styled-components'
import { breakpoints } from '../../theme/breakpoints'

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: ${({ theme }) => theme.color.secondaryBackground};
  padding: 3rem ${({ theme }) => theme.padding.smallMobilePageHorizontalPadding};

  @media ${breakpoints.mobileL} {
    padding: 3rem ${({ theme }) => theme.padding.mobilePageHorizontalPadding};
  }

  @media ${breakpoints.tablet} {
    padding: 4rem ${({ theme }) => theme.padding.smallPageHorizontalPadding};
  }

  @media ${breakpoints.laptopM} {
    padding: 6rem ${({ theme }) => theme.padding.smallPageHorizontalPadding};
  }
  
`
