import React from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import { cloudinarySafeLoader } from '../../../core/application/utils/imageUtils'

export interface IAvatarImageProps {
  src: string
  alt?: string
  width?: number
  height?: number
  quality?: number
}

const RoundedSquareImage: React.VFC<IAvatarImageProps> = ({ src, alt, width, height, quality }) => {
  return (
    <Container>
      <Image
        src={src}
        loader={cloudinarySafeLoader}
        width={width}
        height={height}
        quality={quality}
        alt={alt}
      >
      </Image>
    </Container>
  )
}

const Container = styled.div`
  img {
    border-radius: 0.4rem !important;
  }
`

export default RoundedSquareImage
