import React from 'react'
import { FormattedMessage } from 'react-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { breakpoints } from '../../theme/breakpoints'
import routes from '../../core/application/routes'

const Footer = () => {
  const router = useRouter()
  return (
    <Container>
      <LinkSection>
        <Row>
          <Teaser>
            Share games,<br />
            play more.
          </Teaser>
        </Row>
        <Row>
          <FooterLink disabled>
            <FormattedMessage id='Footer.Link.HowItWork' />
          </FooterLink>
          <FooterLink disabled>
            <FormattedMessage id='Footer.Link.WhoAreWe' />
          </FooterLink>
          <FooterLink disabled>
            <FormattedMessage id='Footer.Link.Faq' />
          </FooterLink>
        </Row>
        <Row>
          <FooterLink disabled>
            <FormattedMessage id='Footer.Link.Contact' />
          </FooterLink>
          <FooterLink disabled>
            Facebook
          </FooterLink>
          <FooterLink disabled>
            Twitter
          </FooterLink>
        </Row>
        <Row>
          <FooterLink>
            <Link href={routes.REGISTRATION}>
              <a>
                <FormattedMessage id='Footer.Link.Register' />
              </a>
            </Link>
          </FooterLink>
          <FooterLink>
            <Link href={routes.LOGIN}>
              <a>
                <FormattedMessage id='Footer.Link.Login' />
              </a>
            </Link>
          </FooterLink>
        </Row>
      </LinkSection>
      <CopyrightSection>
        <Copyright>
          © 2022 Swappy
        </Copyright>
        <CopyrightLinks>
          <a>
            <FormattedMessage id='Footer.Link.LegalInformation' />
          </a>
          <a>
            <FormattedMessage id='Footer.Link.Privacy' />
          </a>
          {router.locale !== 'fr' ?
            <Link href={router.asPath} locale='fr'>
              <a>
                Francais
              </a>
            </Link> :
            <Link href={router.asPath} locale='en'>
              <a>
                English
              </a>
            </Link>
          }
        </CopyrightLinks>
      </CopyrightSection>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem ${({ theme }) => theme.padding.mobilePageHorizontalPadding};


  @media ${breakpoints.tablet} {
    padding: 8rem ${({ theme }) => theme.padding.pageHorizontalPadding};
  }
`

const LinkSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 6rem;
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};
  width: 100%;
  row-gap: 1rem;

  @media ${breakpoints.tablet} {
    flex-direction: row;
    row-gap: unset;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;

  @media ${breakpoints.tablet} {
    align-items: unset;
    row-gap: 0.7rem;
  }
`

interface IFooterLinkProps {
  disabled?: boolean
}

const FooterLink = styled.div<IFooterLinkProps>`
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: ${({ theme }) => theme.size.normal};
  color: ${({ theme, disabled }) => disabled ? theme.color.grey : theme.color.black};

  a {
    color: ${({ theme, disabled }) => disabled ? theme.color.grey : theme.color.black};
    cursor: pointer;
    text-decoration: none;
  }
`


const Teaser = styled.div`
  text-align: center;
  font-weight: ${({ theme }) => theme.weight.bold};
  font-size: ${({ theme }) => theme.size.normal};
  color: ${({ theme }) => theme.color.black};

  @media ${breakpoints.tablet} {
    text-align: unset;
  }
`

const CopyrightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 6rem;
  row-gap: 1rem;

  @media ${breakpoints.tablet} {
    flex-direction: row;
    row-gap: unset;
  }
`


const Copyright = styled.div`
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: ${({ theme }) => theme.size.normal};
  color: ${({ theme }) => theme.color.grey};
  text-align: center;
`

const CopyrightLinks = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 2rem;

  align-items: center;
  justify-content: center;

  @media ${breakpoints.tablet} {
    flex-direction: row;
  }

  a {
    font-weight: ${({ theme }) => theme.weight.normal};
    font-size: ${({ theme }) => theme.size.normal};
    color: ${({ theme }) => theme.color.grey};
    text-decoration: none;
  }
`

export default Footer
