import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { ISwappyProvider } from '../../domain/providers'
import {
  GetUserGamesParams,
  GetUsersParams,
  IAddUserGameData,
  IAddWishData,
  IGlobalStats,
  IOfferAvailableActions,
  IRegisterPayload,
  ISearchGameParams,
  ITopGame,
  ITopUser,
  IUpdateCurrentUserPayload,
  IUpdateUserGameData,
  IUpdateUserWishData, UserStatsData
} from '../../domain/providers/ISwappyProvider'
import {
  Channel,
  Game,
  GameResult,
  Message,
  Notification,
  Offer,
  PublicUser,
  User,
  UserGame,
  UserWish
} from '../../domain/models'
import Parser from './Parser'
import {
  IChannelData,
  IChannelListData, IChannelMessageData,
  ICurrentUserData, ICurrentUserStatsData,
  IGameData, IGameListData,
  IGameSearchResult,
  IGlobalStatsData,
  INotificationListData,
  IOfferAvailableActionsData,
  IOfferListResult,
  IPublicUserData,
  IUserData,
  IUserGameData,
  IUserGameListResult, IUserListData,
  IUserListResult,
  IUserWishData,
  IUserWishListResult
} from './index'
import routes from './routes'
import { OfferActionCode } from '../../domain/models/OfferActionCode'

class SwappyProvider implements ISwappyProvider {
  public httpClient: AxiosInstance
  public authToken?: string

  constructor(baseURL: string) {
    const config: AxiosRequestConfig = {
      baseURL: `${baseURL}/api`,
      // timeout: Number.parseInt(HTTP_CLIENT_DEFAULT_TIMEOUT),
      headers: {
        'content-type': 'application/json'
      }
    }
    this.httpClient = axios.create(config)
  }

  addAuthorizationToken(token: string) {
    this.httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
    this.authToken = token
  }

  async uploadFile(file: File): Promise<string> {
    const formData = new FormData()
    formData.append('file', file, file.name)
    const { data } = await this.httpClient.post(routes.FILE_UPLOAD, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return data
  }

  async register(payload: IRegisterPayload): Promise<void> {
    await this.httpClient.post(routes.REGISTER, payload)
  }

  async getCurrentUser(): Promise<User> {
    const { data } = await this.httpClient.get<ICurrentUserData>(routes.ME)
    const user = data.user
    return Parser.userFromUserData(user)
  }

  async getCurrentUserStats(): Promise<UserStatsData> {
    const { data } = await this.httpClient.get<ICurrentUserStatsData>(`${routes.ME}/stats`)
    return {
      userGames: data.userGames,
      terminatedOffers: data.terminatedOffers
    }
  }

  async getUsers(params: Partial<GetUsersParams>): Promise<Array<PublicUser>> {
    const { data } = await this.httpClient.get<IUserListData>(routes.USERS, {
      params: {
        userIds: params.userIds
      }
    })

    const users = data.items

    return users.map(user => Parser.publicUserFromPublicUserData(user))

  }

  async getUserById(userId: string): Promise<PublicUser> {
    const { data } = await this.httpClient.get<IPublicUserData>(`${routes.USERS}/${userId}`)
    return Parser.publicUserFromPublicUserData(data)
  }

  async getUserGames(userId: string): Promise<Array<UserGame>> {
    const { data } = await this.httpClient.get<IUserGameListResult>(`${routes.USERS}/${userId}/games`)
    return data.items.map(it => Parser.userGameFromUserGamData(it))
  }

  async getUserWishes(userId: string): Promise<Array<UserWish>> {
    const { data } = await this.httpClient.get<IUserWishListResult>(`${routes.USERS}/${userId}/wishes`)
    return data.items.map(it => Parser.UserWishFromUserWishData(it))
  }

  async getUserWish(userWishId: string): Promise<UserWish> {
    const { data } = await this.httpClient.get<IUserWishData>(`${routes.WISH}/${userWishId}`)
    const { data: offerData } = await this.httpClient.get<IOfferListResult>(`${routes.OFFERS}/${userWishId}`)
    const offers = offerData.items.map(offer => Parser.offerFromOfferData(offer))
    return Parser.UserWishFromUserWishData(data, offers)
  }

  async updateCurrentUser(payload: IUpdateCurrentUserPayload): Promise<User> {
    const safePayload = {
      ...payload,
      location: payload.location || null,
      bio: payload.bio || null,
      avatar: payload.avatar || null
    }
    const { data } = await this.httpClient.put(routes.ME, safePayload)
    return Parser.userFromUserData((data as IUserData))
  }

  async updateCurrentUserEmail(email: string): Promise<User> {
    const { data } = await this.httpClient.post(routes.UPDATE_EMAIL, {
      email
    })
    const user = data.user
    return Parser.userFromUserData((user as IUserData))
  }

  async searchGame(query: string, params?: ISearchGameParams, abortSignal?: AbortSignal): Promise<Array<GameResult>> {
    const { data } = await this.httpClient.get<IGameSearchResult>(routes.SEARCH, {
      params: {
        s: query,
        count: params?.count || 5
      },
      signal: abortSignal
    })
    return data.items.map(it => Parser.gameResultFromGameResultData(it))
  }

  async getGame(gameId: string): Promise<Game> {
    const { data } = await this.httpClient.get<IGameData>(`${routes.GAMES}/${gameId}`)
    return Parser.gameFromGameData(data)
  }

  async getAllUserGames(params: Partial<GetUserGamesParams>): Promise<Array<UserGame>> {
    const { data } = await this.httpClient.get<IGameListData>(`${routes.USER_GAMES}/all`, {
      params: {
        userGameIds: params.userGameIds
      }
    })

    const games = data.items

    return games.map(game => Parser.userGameFromUserGamData(game))
  }

  async getGameOwners(gameId: string): Promise<Array<PublicUser>> {
    const { data } = await this.httpClient.get<IUserListResult>(`${routes.GAMES}/${gameId}/owners`)
    return data.items.map(it => Parser.publicUserFromPublicUserData(it))
  }

  async getGameSeekers(gameId: string): Promise<Array<PublicUser>> {
    const { data } = await this.httpClient.get<IUserListResult>(`${routes.GAMES}/${gameId}/seekers`)
    return data.items.map(it => Parser.publicUserFromPublicUserData(it))
  }

  async getCurrentUserGames(): Promise<Array<UserGame>> {
    const { data } = await this.httpClient.get<IUserGameListResult>(routes.USER_GAMES)
    return data.items.map(it => Parser.userGameFromUserGamData(it))
  }

  async getUserGame(userGameId: string): Promise<UserGame> {
    const { data } = await this.httpClient.get<IUserGameData>(`${routes.USER_GAMES}/${userGameId}`)
    return Parser.userGameFromUserGamData(data)
  }


  async addUserGame({ externalId, condition, platform }: IAddUserGameData): Promise<UserGame> {
    const { data } = await this.httpClient.post(routes.USER_GAMES, {
      externalId,
      condition,
      platform
    })
    return Parser.userGameFromUserGamData(data)
  }

  async updateUserGame(userGameId: string, { condition }: IUpdateUserGameData): Promise<UserGame> {
    const { data } = await this.httpClient.put(`${routes.USER_GAMES}/${userGameId}`, {
      condition
    })
    return Parser.userGameFromUserGamData(data)
  }

  async deleteUserGame(userGameId: string): Promise<void> {
    await this.httpClient.delete(`${routes.USER_GAMES}/${userGameId}`)
  }

  async getCurrentUserWishes(): Promise<Array<UserWish>> {
    const { data } = await this.httpClient.get<IUserWishListResult>(routes.WISH)
    const { data: offerData } = await this.httpClient.get<IOfferListResult>(routes.OFFERS)
    return data.items.map(wish => {
      const offers = offerData.items
        .filter(offer => offer.proposals[0].wish.id === wish.id || offer.proposals[1].wish.id === wish.id)
        .map(offer => Parser.offerFromOfferData(offer))
      return Parser.UserWishFromUserWishData(wish, offers)
    })
  }

  async addUserWish({ externalId, conditions, platform, minNote }: IAddWishData): Promise<UserWish> {
    const { data } = await this.httpClient.post(routes.WISH, {
      externalId,
      conditions,
      platform,
      minNote
    })
    return Parser.UserWishFromUserWishData(data)
  }

  async updateUserWish(UserWishId: string, { conditions, platform, minNote }: IUpdateUserWishData): Promise<UserWish> {
    const { data } = await this.httpClient.put(`${routes.WISH}/${UserWishId}`, {
      conditions,
      platform,
      minNote
    })
    return Parser.UserWishFromUserWishData(data)
  }

  async deleteUserWish(UserWishId: string): Promise<void> {
    await this.httpClient.delete(`${routes.WISH}/${UserWishId}`)
  }

  async getNotificationList(): Promise<Array<Notification>> {
    const { data } = await this.httpClient.get<INotificationListData>(routes.NOTIFICATIONS)
    return data.items.map(it => Parser.notificationFromNotificationData(it))
  }

  async markNotificationHasRead(notificationId: string): Promise<Notification> {
    const { data } = await this.httpClient.put(`${routes.NOTIFICATIONS}/${notificationId}`)
    return Parser.notificationFromNotificationData(data)
  }

  async removeNotification(notificationId: string): Promise<void> {
    await this.httpClient.delete(`${routes.NOTIFICATIONS}/${notificationId}`)
  }


  async getGlobalStats(): Promise<IGlobalStats> {
    const { data } = await this.httpClient.get<IGlobalStatsData>(routes.GLOBAL_STATS)

    const topUsers: Array<ITopUser> = data.topUsers.map(it => {
      return {
        user: Parser.publicUserFromPublicUserData(it.user),
        games: it.games,
        exchanges: it.exchanges
      }
    })
    const topGames: Array<ITopGame> = data.topGames.map(it => {
      return {
        game: Parser.gameFromGameData(it.game),
        owners: it.owners,
        seekers: it.seekers
      }
    })

    return {
      topGames,
      topUsers
    }
  }

  async getOfferAvailableActions(offerId: string): Promise<IOfferAvailableActions> {
    const { data } = await this.httpClient.get<IOfferAvailableActionsData>(`${routes.OFFERS}/${offerId}/actions`)

    return data
  }

  async sendOffer(offerId: string): Promise<Offer> {
    const { data } = await this.httpClient.post(`${routes.OFFERS}/${offerId}/actions`, {
      action: OfferActionCode.SEND_OFFER
    })

    return Parser.offerFromOfferData(data)

  }

  async acceptOffer(offerId: string): Promise<Offer> {
    const { data } = await this.httpClient.post(`${routes.OFFERS}/${offerId}/actions`, {
      action: OfferActionCode.ACCEPT_OFFER
    })

    return Parser.offerFromOfferData(data)

  }

  async refuseOffer(offerId: string): Promise<Offer> {
    const { data } = await this.httpClient.post(`${routes.OFFERS}/${offerId}/actions`, {
      action: OfferActionCode.REFUSE_OFFER
    })

    return Parser.offerFromOfferData(data)

  }

  async cancelOffer(offerId: string): Promise<Offer> {
    const { data } = await this.httpClient.post(`${routes.OFFERS}/${offerId}/actions`, {
      action: OfferActionCode.CANCEL_OFFER
    })

    return Parser.offerFromOfferData(data)

  }

  async cancelExchange(offerId: string): Promise<Offer> {
    const { data } = await this.httpClient.post(`${routes.OFFERS}/${offerId}/actions`, {
      action: OfferActionCode.CANCEL_EXCHANGE
    })

    return Parser.offerFromOfferData(data)

  }

  async confirmExchange(offerId: string): Promise<Offer> {
    const { data } = await this.httpClient.post(`${routes.OFFERS}/${offerId}/actions`, {
      action: OfferActionCode.CONFIRM_EXCHANGE
    })
    return Parser.offerFromOfferData(data)
  }

  async createNewChannel(swapperId: string): Promise<Channel> {
    const { data } = await this.httpClient.post<IChannelData>(routes.CHANNELS, {
      swapperId
    })
    return Parser.channelFromChannelData(data)
  }

  async getChannel(channelId: string): Promise<Channel> {
    const { data } = await this.httpClient.get<IChannelData>(`${routes.CHANNELS}/${channelId}`)
    return Parser.channelFromChannelData(data)
  }

  async markChannelAsRead(channelId: string): Promise<Channel> {
    const { data } = await this.httpClient.put<IChannelData>(`${routes.CHANNELS}/${channelId}`)
    return Parser.channelFromChannelData(data)
  }

  async getChannelList(): Promise<Array<Channel>> {
    const { data } = await this.httpClient.get<IChannelListData>(routes.CHANNELS)
    return data.items.map((it) => Parser.channelFromChannelData(it))
  }

  async sendMessage(channelId: string, content: string): Promise<Message> {
    const { data } = await this.httpClient.post<IChannelMessageData>(`${routes.CHANNELS}/${channelId}`, {
      content
    })
    return Parser.messageFromMessageData(data)

  }


}

export default SwappyProvider
