import React from 'react'
import { User } from '../../domain/models'

export interface IUserContext {
  userAuthId?: string
  setUserAuthId: (userAuthId: string) => void
  user?: User
  setUser: (user: User) => void
  userGames: number
  setUserGames:(count: number) => void
  userWishes: number
  setUserWishes:(count: number) => void
  terminatedOffers: number
  setTerminatedOffers:(count: number) => void
  removeUser: () => void
}

export const UserContext = React.createContext<IUserContext>({
  setUserAuthId: () => {
    console.warn(
      'setUserAuthId method not implemented in current context'
    )
  },
  setUser: () => {
    console.warn(
      'setUser method not implemented in current context'
    )
  },
  removeUser: () => {
    console.warn(
      'setUser method not implemented in current context'
    )
  },
  userGames: 0,
  setUserGames: () => {
    console.warn(
      'setUserGames method not implemented in current context'
    )
  },
  userWishes: 0,
  setUserWishes: () => {
    console.warn(
      'setUserWishes method not implemented in current context'
    )
  },
  terminatedOffers: 0,
  setTerminatedOffers: () => {
    console.warn(
      'setTerminatedOffers method not implemented in current context'
    )
  }

})

export const useUserContext = (): IUserContext => React.useContext(UserContext)
