import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Link from 'next/link'
import styled from 'styled-components'
import { Router } from 'next/router'
import { IdProvider } from '@radix-ui/react-id'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import routes from '../../core/application/routes'

export interface INavbarDropdownProps {
  isOpen?: boolean
  onOpen?: (value: boolean) => void
  onLogout: () => void
}

const NavbarDropdown: React.FC<INavbarDropdownProps> = ({ children, isOpen, onOpen, onLogout }) => {

  useEffect(() => {
    const handleRouteChange = () => {
      onOpen && onOpen(false)
    }
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [onOpen])


  return (
    <IdProvider>
      <DropdownMenu.Root open={isOpen} onOpenChange={onOpen}>
        <DropdownMenu.Trigger>
          {children}
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <Container>
            <Item>
              <Link href={routes.ACCOUNT} passHref>
                <a>
                  <FormattedMessage id='NavbarDropdown.Item.Profil' />
                </a>
              </Link>
            </Item>
            {false && <>
              <Item>
                <Link href={routes.ACCOUNT} passHref>
                  <a>
                    <FormattedMessage id='NavbarDropdown.Item.Trophy' />
                  </a>
                </Link>
              </Item>
              <Item>
                <Link href={routes.ACCOUNT} passHref>
                  <a>
                    <FormattedMessage id='NavbarDropdown.Item.Review' />
                  </a>
                </Link>
              </Item>
            </>}
            <Separator />
            <Item>
              <a onClick={() => onLogout()}>
                <FormattedMessage id='NavbarDropdown.Item.Logout' />
              </a>
            </Item>
          </Container>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </IdProvider>
  )
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 22rem;
  background-color: white;
  padding: 1.4rem 2.2rem;
  border-radius: 0.4rem;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid ${({ theme }) => theme.color.lightGrey};
  position: relative;


  row-gap: 0.5rem;


`

const Item = styled(DropdownMenu.Item)`
  font-weight: ${({ theme }) => theme.weight.semi};
  font-size: ${({ theme }) => theme.size.normal};

  a {
    color: ${({ theme }) => theme.color.black};
    text-decoration: none;
    cursor: pointer;
  }
`

const Separator = styled(DropdownMenu.Separator)`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 0.1rem;
  background-color: #e9e9e9;
`

export default NavbarDropdown
