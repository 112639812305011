import styled from 'styled-components'

const LargeButton = styled.button`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  padding: 1.4rem 3.1rem;
  border-radius: 0.4rem;
  box-shadow: 0 5px 20px 0 rgba(136, 99, 232, 0.5);
  font-weight: ${({ theme }) => theme.weight.semi};
  font-size: ${({ theme }) => theme.size.normal};
  transition: box-shadow 200ms ease-out, transform 100ms ease-out;
  cursor: pointer;
  min-width: 20rem;

  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;
  }


  &:hover {
    box-shadow: 0 10px 20px 0 rgba(136, 99, 232, 0.5);
    transform: scale(1.05);
  }

`

export default LargeButton
