import React from 'react'
import FaIconContainer from './FaIconContainer'
import { IFaIconProps } from './types'

const FaTwoFingersIcon: React.FC<IFaIconProps> = ({ customClass, size }) => {
  return (
    <FaIconContainer className={customClass} size={size}>
      <svg className={customClass} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <path
          d='M256 287.4V32c0-17.67-14.31-32-32-32S192 14.33 192 32v216.3C218.7 248.4 243.7 263.1 256 287.4zM170.8 251.2c2.514-.7734 5.043-1.027 7.57-1.516L93.41 51.39C88.21 39.25 76.34 31.97 63.97 31.97c-20.97 0-31.97 18.01-31.97 32.04c0 4.207 .8349 8.483 2.599 12.6l81.97 191.3L170.8 251.2zM416 224c-17.69 0-32 14.33-32 32v64c0 17.67 14.31 32 32 32s32-14.33 32-32V256C448 238.3 433.7 224 416 224zM320 352c17.69 0 32-14.33 32-32V224c0-17.67-14.31-32-32-32s-32 14.33-32 32v96C288 337.7 302.3 352 320 352zM368 361.9C356.3 375.3 339.2 384 320 384c-27.41 0-50.62-17.32-59.73-41.55c-7.059 21.41-23.9 39.23-47.08 46.36l-47.96 14.76c-1.562 .4807-3.147 .7105-4.707 .7105c-6.282 0-12.18-3.723-14.74-9.785c-.8595-2.038-1.264-4.145-1.264-6.213c0-6.79 4.361-13.16 11.3-15.3l46.45-14.29c17.2-5.293 29.76-20.98 29.76-38.63c0-34.19-32.54-40.07-40.02-40.07c-3.89 0-7.848 .5712-11.76 1.772l-104 32c-18.23 5.606-28.25 22.21-28.25 38.22c0 4.266 .6825 8.544 2.058 12.67L68.19 419C86.71 474.5 138.7 512 197.2 512H272c82.54 0 151.8-57.21 170.7-134C434.6 381.8 425.6 384 416 384C396.8 384 379.7 375.3 368 361.9z' />
      </svg>
    </FaIconContainer>
  )
}


export default FaTwoFingersIcon
