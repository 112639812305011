import React from 'react'
import { useIntl } from 'react-intl'
import * as Toast from '@radix-ui/react-toast'
import styled, { css, keyframes } from 'styled-components'
import { breakpoints } from '../../theme/breakpoints'
import { ToastMode } from '../../core/application/types'
import { FaBellIcon, FaCheckIcon, FaCircleXMarkIcon } from '../icons'
import FaCircleExclamationIcon from '../icons/FaCircleExclamationIcon'
import { TOAST_DURATION } from '../../core/constants'


export interface IMyToastProps {
  open: boolean
  onOpenChange: (value: boolean) => void
  title?: string
  description?: string
  mode?: ToastMode
}

const MyToast: React.VFC<IMyToastProps> = ({ onOpenChange, open, title, description, mode = ToastMode.INFO }) => {
  const intl = useIntl()
  const getToastTitle = (value: string | undefined): string => {
    if (value) {
      return value
    } else if (mode === ToastMode.SUCCESS) {
      return intl.formatMessage({
        id: 'MyToast.Title.Success'
      })
    } else if (mode === ToastMode.WARN) {
      return intl.formatMessage({
        id: 'MyToast.Title.Warn'
      })
    } else if (mode === ToastMode.ERROR) {
      return intl.formatMessage({
        id: 'MyToast.Title.Error'
      })
    }
    return intl.formatMessage({
      id: 'MyToast.Title.Info'
    })
  }

  const mobileIconSize = {
    height: 2.5,
    width: 2.5
  }

  return (
    <Toast.Provider duration={TOAST_DURATION}>
      <Container mode={mode} open={open} onOpenChange={onOpenChange}>
        <Wrapper>
          <TopRow>
            <ToastTitle mode={mode}>
              {getToastTitle(title)}
            </ToastTitle>
            <ActionWrapper>
              <CloseIcon>
                {mode === ToastMode.INFO && <FaBellIcon customClass='primary' />}
                {mode === ToastMode.WARN && <FaCircleExclamationIcon customClass='gold' />}
                {mode === ToastMode.ERROR && <FaCircleXMarkIcon customClass='red' />}
                {mode === ToastMode.SUCCESS && <FaCheckIcon customClass='green' />}
              </CloseIcon>
              <MobileCloseIcon>
                {mode === ToastMode.INFO && <FaBellIcon customClass='primary' size={mobileIconSize} />}
                {mode === ToastMode.WARN && <FaCircleExclamationIcon customClass='gold' size={mobileIconSize} />}
                {mode === ToastMode.ERROR && <FaCircleXMarkIcon customClass='red' size={mobileIconSize} />}
                {mode === ToastMode.SUCCESS && <FaCheckIcon customClass='green' size={mobileIconSize} />}
              </MobileCloseIcon>
            </ActionWrapper>
          </TopRow>
          {description && <ToastDescription>
            {description}
          </ToastDescription>}
        </Wrapper>
      </Container>
      <Viewport mode={mode} />
    </Toast.Provider>
  )
}

const VIEWPORT_PADDING = '1'

const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 }
})

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}rem))` },
  to: { transform: 'translateX(0)' }
})

const swipeOut = keyframes({
  from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
  to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` }
})

interface ModeProps {
  mode: ToastMode
}

const Viewport = styled(Toast.Viewport)<ModeProps>`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 100;
  list-style: none;
  padding: 1rem;

  @media ${breakpoints.tablet} {
    width: 40rem;
    max-width: 50rem;
  }
`


const Container = styled(Toast.Root)<ModeProps>`
  background-color: orange;
  border-radius: 0.4rem;
  box-shadow: rgb(14 18 22 / 35%) 0 10px 38px -10px, rgb(14 18 22 / 20%) 0 10px 20px -15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${({ mode, theme }) => mode === ToastMode.INFO && css`
    background-color: ${theme.color.primary};
  `};

  ${({ mode, theme }) => mode === ToastMode.SUCCESS && css`
    background-color: ${theme.color.green};
  `};

  ${({ mode, theme }) => mode === ToastMode.WARN && css`
    background-color: ${theme.color.gold};
  `};

  ${({ mode, theme }) => mode === ToastMode.ERROR && css`
    background-color: ${theme.color.red};
  `};


  &[data-state='open'] {
    animation: ${() => css`
      ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)
    `};
  }

  &[data-state='closed'] {
    animation: ${() => css`
      ${hide} 100ms ease-in forwards
    `};
  }

  &[data-state='move'] {
    transform: translateX(var(--radix-toast-swipe-move-x))
  }

  &[data-state='cancel'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }

  &[data-state='end'] {
    animation: ${() => css`
      ${swipeOut} 100ms ease-out forwards
    `};
  }
`

const Wrapper = styled.div`
  left: 6px;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1.1rem 1.8rem 1.8rem 1.6rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.6rem;
  background-color: white;
  border: solid 1px #e9e9e9;
  border-radius: 0.4rem;
`

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ToastTitle = styled(Toast.Title)<ModeProps>`
  letter-spacing: 0.18rem;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.weight.bold};

  @media ${breakpoints.laptop} {
    font-size: 1rem;
  }

  ${({ mode }) => mode === ToastMode.INFO && css`
    color: ${({ theme }) => theme.color.primary};
  `};

  ${({ mode }) => mode === ToastMode.SUCCESS && css`
    color: ${({ theme }) => theme.color.green};
  `};

  ${({ mode }) => mode === ToastMode.WARN && css`
    color: ${({ theme }) => theme.color.gold};
  `};

  ${({ mode }) => mode === ToastMode.ERROR && css`
    color: ${({ theme }) => theme.color.red};
  `};
`

const ToastDescription = styled(Toast.Description)`
  color: ${({ theme }) => theme.color.grey};
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: ${({ theme }) => theme.size.normal};

`

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const CloseButton = styled(Toast.Close)`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  font-weight: ${({ theme }) => theme.weight.semi};
  font-size: ${({ theme }) => theme.size.normal};
  padding: 1.4rem 3rem;
  box-shadow: 0 5px 20px 0 rgba(136, 99, 232, 0.5);
  border-radius: 0.4rem;
  cursor: pointer;
`

const CloseIcon = styled(Toast.Close)`
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;

  @media ${breakpoints.laptop} {
    display: flex;
  }
`

const MobileCloseIcon = styled(CloseIcon)`
  display: flex;
  @media ${breakpoints.laptop} {
    display: none;
  }
`

export default MyToast
