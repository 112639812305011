const FIRST_NAME_MIN_LENGTH = 3
const FIRST_NAME_MAX_LENGTH = 20

const LAST_NAME_MIN_LENGTH = 3
const LAST_NAME_MAX_LENGTH = 20

const USERNAME_MIN_LENGTH = 5
const USERNAME_MAX_LENGTH = 20

const PASSWORD_MIN_LENGTH = 8
const PASSWORD_MAX_LENGTH = 50

const USER_RANGE_MIN_VALUE = 1
const USER_MANGE_MAX_VALUE = 1000

const USER_AVATAR_PLACEHOLDER = '/images/blank-profile-picture.png'

// Bytes/Octects > 10mo
const FILE_UPLOAD_MAX_SIZE = 9 * 1024 * 1024

const TOAST_DURATION = 4000

export {
  FIRST_NAME_MIN_LENGTH,
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MIN_LENGTH,
  LAST_NAME_MAX_LENGTH,
  USERNAME_MIN_LENGTH,
  USERNAME_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  USER_RANGE_MIN_VALUE,
  USER_MANGE_MAX_VALUE,
  USER_AVATAR_PLACEHOLDER,
  FILE_UPLOAD_MAX_SIZE,
  TOAST_DURATION
}
