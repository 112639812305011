import styled from 'styled-components'
import * as Dialog from '@radix-ui/react-dialog'

export default styled(Dialog.Title)`
  font-weight: ${({ theme }) => theme.weight.bold};
  font-size: ${({ theme }) => theme.size.notNormal};
  padding: 4rem 2.7rem;
  border-bottom: solid 1px ${({ theme }) => theme.color.lightGrey};

  &.center {
    text-align: center;
  }
`
