import React from 'react'
import { IdProvider } from '@radix-ui/react-id'
import * as Checkbox from '@radix-ui/react-checkbox'
import styled from 'styled-components'
import { FaCheckIcon } from '../../icons'
import { ICheckboxGroupItemOption } from '../../../core/application/types'

export interface ICustomCheckboxGroupProps {
  groupId: string
  groupLabel: string
  options: Array<ICheckboxGroupItemOption>
  currentValues?: Array<any>
  onChange: (value: any) => void
}

const CustomCheckboxGroup: React.FC<ICustomCheckboxGroupProps> = ({
  groupId,
  groupLabel,
  currentValues,
  options,
  onChange
}) => {
  return (
    <IdProvider>
      <Container>
        <GroupLabel>
          {groupLabel}
        </GroupLabel>
        <GridWrapper>
          {options.map((it, index) => (
            <ItemContainer key={index}>
              <CustomCheckboxItem
                id={`${groupId}-${index}`} checked={currentValues?.includes(it.value)}
                onCheckedChange={() => onChange(it.value)}>
                <CustomIndicator>
                  <FaCheckIcon customClass='primary' size={{ height: 1.3, width: 1.3 }} />
                </CustomIndicator>
              </CustomCheckboxItem>
              {it.label &&
                <CustomLabel htmlFor={`${groupId}-${index}`}>
                  {it.label}
                </CustomLabel>}
            </ItemContainer>
          ))}
        </GridWrapper>
      </Container>
    </IdProvider>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`


const GroupLabel = styled.div`
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: ${({ theme }) => theme.size.small};
  color: ${({ theme }) => theme.color.black};
  margin-bottom: 1rem;
`


const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  column-gap: 1.2rem;
`

const CustomCheckboxItem = styled(Checkbox.Root)`
  background-color: white;
  width: 2rem;
  height: 2rem;
  border-radius: 20%;
  border: solid 1px ${({ theme }) => theme.color.black};
`

const CustomIndicator = styled(Checkbox.Indicator)`
`

const CustomLabel = styled.label`
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: ${({ theme }) => theme.size.small};
  color: ${({ theme }) => theme.color.black};
  text-transform: uppercase;
`

export default CustomCheckboxGroup
