const SWAPPY_API_ENDPOINT = process.env.NEXT_PUBLIC_GAME_SWAPPY_API_ENDPOINT || 'https://swappy-api-dev.herokuapp.com'

const NODE_ENV = process.env.NODE_ENV || 'development'

const INDEX_PAGE_CACHE_TIMELIFE = process.env.INDEX_PAGE_CACHE_TIMELIFE || 60

const firebaseConfig = {
  apiKey: 'AIzaSyAezlm9FBQ7ZcF37v7tiIPcG_d2rlkj7L0',
  authDomain: 'swappy-games.firebaseapp.com',
  projectId: 'swappy-games',
  storageBucket: 'swappy-games.appspot.com',
  messagingSenderId: '236115321730',
  appId: '1:236115321730:web:dc64faf41fcfb587a8259b',
  measurementId: 'G-VH6XT4Q49V'
}

export {
  SWAPPY_API_ENDPOINT,
  NODE_ENV,
  firebaseConfig,
  INDEX_PAGE_CACHE_TIMELIFE
}
