import { OfferActionCode } from './OfferActionCode'


class OfferAction {
  userId: string
  code: OfferActionCode
  date: Date

  constructor(userId: string, date: Date, code: OfferActionCode) {
    this.userId = userId
    this.code = code
    this.date = date
  }
}

export default OfferAction
