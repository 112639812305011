import { Auth, UserCredential } from '@firebase/auth'
import {
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword
} from 'firebase/auth'
import { IFirebaseError, isFirebaseError } from '../../application/utils/firebaseUtils'
import { AuthProviderError } from '../../domain/providers'
import { FirebaseAuthErrorCode } from '../../domain/providers/errors'

import { IAuthData, IAuthProvider } from '../../domain/providers/IAuthProvider'


class FirebaseAuthProvider implements IAuthProvider {
  firebaseAuth: Auth

  constructor(firebaseAuth: Auth) {
    this.firebaseAuth = firebaseAuth
  }

  handleFirebaseError(error: IFirebaseError): AuthProviderError {
    console.error('errorCode > ', error.code)
    console.error('errorMessage > ', error.message)

    switch (error.code) {
      case 'auth/weak-password':
        return new AuthProviderError(error.message, FirebaseAuthErrorCode.WEAK_PASSWORD)
      case 'auth/email-already-in-use':
        return new AuthProviderError(error.message, FirebaseAuthErrorCode.EMAIL_ALREADY_USED)
      case 'auth/user-not-found':
        return new AuthProviderError(error.message, FirebaseAuthErrorCode.USER_NOT_FOUND)
      case 'auth/wrong-password':
        return new AuthProviderError(error.message, FirebaseAuthErrorCode.WRONG_PASSWORD)
      case 'auth/requires-recent-login':
        return new AuthProviderError(error.message, FirebaseAuthErrorCode.TOKEN_EXPIRED)
      default:
        return new AuthProviderError(error.message, FirebaseAuthErrorCode.UNKNOWN_FIREBASE_ERROR)
    }
  }


  async login(email: string, password: string): Promise<IAuthData> {
    try {
      const { user }: UserCredential = await signInWithEmailAndPassword(this.firebaseAuth, email, password)
      return {
        uid: user.uid,
        email: user.email || undefined
      }
    } catch (error) {
      if (isFirebaseError(error)) {
        throw this.handleFirebaseError(error)
      }
      throw new AuthProviderError('login_error', FirebaseAuthErrorCode.UNKNOWN_FIREBASE_ERROR)
    }
  }

  // async register({ email, password }: IRegisterData): Promise<IAuthData> {
  //   try {
  //     const { user }: UserCredential = await createUserWithEmailAndPassword(this.firebaseAuth, email, password)
  //     return {
  //       uid: user.uid,
  //       email: user.email || undefined
  //     }
  //   } catch (error) {
  //     if (isFirebaseError(error)) {
  //       throw this.handleFirebaseError(error)
  //     }
  //     throw new AuthProviderError('register_error', FirebaseAuthErrorCode.UNKNOWN_FIREBASE_ERROR)
  //   }
  //
  // }

  async resetPassword(email: string): Promise<void> {
    try {
      const response = await sendPasswordResetEmail(this.firebaseAuth, email)
    } catch (error) {
      if (isFirebaseError(error)) {
        throw this.handleFirebaseError(error)
      }
      throw new AuthProviderError('reset_password_error', FirebaseAuthErrorCode.UNKNOWN_FIREBASE_ERROR)
    }
  }

  async getAuthToken(): Promise<string> {
    if (this.firebaseAuth.currentUser) {
      return await this.firebaseAuth.currentUser.getIdToken()
    }
    throw new AuthProviderError('firebaseAuth.currentUser is null', FirebaseAuthErrorCode.USER_NOT_LOGGED)
  }

  async updateUserPassword(password: string): Promise<void> {
    const user = this.firebaseAuth.currentUser
    if (!user) {
      throw new AuthProviderError('firebaseAuth.currentUser is null', FirebaseAuthErrorCode.USER_NOT_LOGGED)
    }
    try {
      await updatePassword(user, password)
    } catch (error) {
      console.error(error)
      if (isFirebaseError(error)) {
        throw this.handleFirebaseError(error)
      }
    }
  }

  async updateUserEmail(email: string): Promise<void> {
    const user = this.firebaseAuth.currentUser
    if (!user) {
      throw new AuthProviderError('firebaseAuth.currentUser is null', FirebaseAuthErrorCode.USER_NOT_LOGGED)
    }
    try {
      await updateEmail(user, email)
    } catch (error) {
      console.error(error)
      if (isFirebaseError(error)) {
        throw this.handleFirebaseError(error)
      }
    }
  }

  onAuthChanged(callback: (authData: IAuthData) => void): void {
    onAuthStateChanged(this.firebaseAuth, (user) => {
      if (user) {
        callback({
          uid: user.uid,
          email: user.email || undefined
        })
      }
    })
  }

  async logout(): Promise<void> {
    await signOut(this.firebaseAuth)
  }


}

export default FirebaseAuthProvider
