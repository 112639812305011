import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'
import { breakpoints } from '../../theme/breakpoints'
import routes from '../../core/application/routes'
import { useUserContext } from '../../core/application/contexts'
import NavbarDropdown from './NavbarDropdown'
import { FaBellIcon } from '../icons'
import { USER_AVATAR_PLACEHOLDER } from '../../core/constants'
import { RoundImage } from '../common/images'
import { useNotificationContext } from '../../core/application/contexts/NotificationContext'


const Navbar: React.FC = () => {
  const router = useRouter()
  const { user, removeUser } = useUserContext()
  const { notificationCount, setNotificationCount } = useNotificationContext()

  const [navbarMenuIsOpen, setNavbarMenuIsOpen] = useState<boolean>(false)

  const handleLogout = async () => {
    removeUser()
    setNotificationCount(0)
    setNavbarMenuIsOpen(false)
    await router.replace(routes.LOGIN)
  }

  return (
    <Container id='navbar'>
      <LogoWrapper>
        <Link href={routes.INDEX}>
          <a>
            <img src='/images/logo.png' alt='Swappy Logo' />
            <span>Swappy</span>
          </a>
        </Link>
      </LogoWrapper>
      <Items>
        <NavbarItem disabled>
          <FormattedMessage id='Navbar.Items.Help' />
        </NavbarItem>
        {user !== undefined ?
          <>
            <NavbarItem current={router.asPath === routes.LIBRARY}>
              <Link href={routes.LIBRARY} passHref>
                <a>
                  <FormattedMessage id='Navbar.Items.Library' />
                </a>
              </Link>
            </NavbarItem>
            <NavbarItem current={router.asPath === routes.WISHLIST}>
              <Link href={routes.WISHLIST} passHref>
                <a>
                  <FormattedMessage id='Navbar.Items.Wishlist' />
                </a>
              </Link>
            </NavbarItem>
            <NavbarItem current={router.asPath === routes.CHAT}>
              <Link href={routes.CHAT} passHref>
                <a>
                  <FormattedMessage id='Navbar.Items.Chat' />
                </a>
              </Link>
            </NavbarItem>
            <NavbarItem current={router.asPath === routes.NOTIFICATIONS}>
              <Link href={routes.NOTIFICATIONS} passHref>
                <a>
                  <FaIconWrapper>
                    <FaBellIcon customClass='black' size={{ height: 1.6, width: 1.6 }} />
                    <PurpleDot visible={notificationCount > 0} />
                  </FaIconWrapper>
                </a>
              </Link>
            </NavbarItem>
            <NavbarDropdown isOpen={navbarMenuIsOpen} onOpen={setNavbarMenuIsOpen} onLogout={handleLogout}>
              <UserAccountItem current={router.asPath === routes.ACCOUNT}>
                <a>
                  {user.avatar ?
                    <RoundImage src={user.avatar} width={54} height={54} quality={100} alt={user.username} /> :
                    <img src={USER_AVATAR_PLACEHOLDER} />
                  }
                </a>
              </UserAccountItem>
            </NavbarDropdown>
          </> :
          <>
            <NavbarItem current={router.asPath === routes.LOGIN}>
              <Link href={routes.LOGIN} passHref>
                <a>
                  <FormattedMessage id='Navbar.Items.Login' />
                </a>
              </Link>
            </NavbarItem>
            <NavbarItem current={router.asPath === routes.REGISTRATION}>
              <Link href={routes.REGISTRATION} passHref>
                <a>
                  <FormattedMessage id='Navbar.Items.Registration' />
                </a>
              </Link>
            </NavbarItem>
          </>
        }
      </Items>
    </Container>
  )
}

const Container = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${({ theme }) => theme.color.lightGrey};
  min-height: 8rem;

  @media ${breakpoints.tablet} {
    padding: 0 3rem;
  }

  @media ${breakpoints.laptop} {
    padding: 0 11.5rem;
  }
`

const LogoWrapper = styled.div`
  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.color.black};
  }


  img {
    max-height: 5.5rem;
  }

  span {
    font-size: 20px;
    font-weight: bold;
  }
`


const Items = styled.div`
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 5rem;

  @media ${breakpoints.tablet} {
    display: flex;
  }
`

interface IItemsProps {
  disabled?: boolean
  current?: boolean
}

const NavbarItem = styled.div<IItemsProps>`
  padding: 3rem 0;
  font-weight: ${({ theme }) => theme.weight.semi};
  font-size: ${({ theme }) => theme.size.normal};
  color: ${({ theme, disabled }) => disabled ? theme.color.grey : theme.color.black};
  border-bottom: solid 2px ${({ theme, current }) => current ? theme.color.primary : 'transparent'};
  transition: border 200ms ease-out;
  min-width: 3rem;

  a {
    color: ${({ theme, disabled }) => disabled ? theme.color.grey : theme.color.black};
    text-decoration: none;
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
    pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
  }

`

const FaIconWrapper = styled.div`
  position: relative;
`

interface IPurpleDotProps {
  visible: boolean
}

const PurpleDot = styled.div<IPurpleDotProps>`
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 50%;
  height: 1px;
  width: 1px;
  position: absolute;
  top: -0.45px;
  left: 25px;
  visibility: hidden;
  transition: transform 200ms ease-out;

  ${({ visible }) => visible && css`
    transform: scale(11);
    visibility: visible;
  `};
`

const UserAccountItem = styled.div<IItemsProps>`
  height: 5.4rem;
  width: 5.4rem;

  a {
    cursor: pointer;
  }

  img {
    border-radius: 50%;
    border: 3px solid ${({ theme, current }) => current ? theme.color.primary : theme.color.lightGrey} !important;
    transition: border 200ms ease-out;
  }

`


export default Navbar
