import Game from './Game'
import { GamePlatform } from './GamePlatform'
import { GameCondition } from './GameCondition'
import { OfferStatus } from './OfferStatus'
import Offer from './Offer'

export interface IUserWishProps {
  id: string
  range: number
  minNote: number
  game: Game
  conditions: Array<GameCondition>
  offers: Array<Offer>
  platform: GamePlatform
}

class UserWish {
  public id: string
  public range: number
  public minNote: number
  public game: Game
  public conditions: Array<GameCondition>
  public platform: GamePlatform
  public offers: Array<Offer>


  constructor({ id, game, conditions, platform, range, minNote, offers }: IUserWishProps) {
    this.id = id
    this.game = game
    this.conditions = conditions
    this.platform = platform
    this.range = range
    this.minNote = minNote
    this.offers = offers
  }

  get hasOffers(): boolean {
    return this.offers.filter(it => it.status === OfferStatus.OFFER).length > 0
  }

  get hasMatchs(): boolean {
    return this.offers.filter(it => it.status === OfferStatus.MATCH).length > 0
  }

  get hasExchanges(): boolean {
    return this.offers.filter(it => it.status === OfferStatus.EXCHANGE).length > 0
  }
}


export default UserWish
