import React from 'react'
import styled from 'styled-components'
import * as Dialog from '@radix-ui/react-dialog'

import ModalHeader from './ModalHeader'
import DefaultModal from './DefaultModal'
import ModalWrapper from './ModalWrapper'

import ModalBottomSection from './ModalBottomSection'
import FullSizeFlatButton from '../../common/buttons/FullSizeFlatButton'

export interface IErrorModalProps {
  isOpen?: boolean
  onOpen: (value: boolean) => void
  errorMessage: string
}

const ErrorModal: React.VFC<IErrorModalProps> = ({ onOpen, isOpen, errorMessage }) => {
  return (
    <DefaultModal onOpen={onOpen} isOpen={isOpen}>
      <ModalHeader>
        Oups 🚨
      </ModalHeader>
      <Dialog.Description asChild>
        <ModalWrapper>
          <ErrorWrapper>
            {errorMessage}
          </ErrorWrapper>
        </ModalWrapper>
      </Dialog.Description>
      <ModalBottomSection>
        <FullSizeFlatButton onClick={() => onOpen(false)}>
          Fermer
        </FullSizeFlatButton>
      </ModalBottomSection>
    </DefaultModal>
  )
}

const ErrorWrapper = styled.div`
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: ${({ theme }) => theme.size.mobileMinSize};
  color: ${({ theme }) => theme.color.grey};
`

export default ErrorModal
