import React from 'react'

export interface IErrorModalContext {
  errorModalMessage?: string
  setErrorModalMessage: (error: string) => void
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

export const ErrorModalContext = React.createContext<IErrorModalContext>({
  setErrorModalMessage: () => {
    console.warn(
      'setErrorModalMessage method not implemented in current context'
    )
  },
  isOpen: false,
  setIsOpen: () => {
    console.warn(
      'setIsOpen method not implemented in current context'
    )
  }
})

export const useErrorModalContext = (): IErrorModalContext =>
  React.useContext(ErrorModalContext)
