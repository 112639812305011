import { ILocalizedContent } from './LocalizedContent'

export interface INotificationProps {
  id: string
  date: Date
  isNew: boolean
  localizedContents: Array<ILocalizedContent>
}

class Notification {
  id: string
  date: Date
  isNew: boolean
  localizedContents: Array<ILocalizedContent>

  constructor({ id, date, isNew, localizedContents }: INotificationProps) {
    this.id = id
    this.date = date
    this.isNew = isNew
    this.localizedContents = localizedContents
  }

  get defaultContent(): string {
    const content = this.localizedContents.find(it => it.lang === 'en')
    return content?.content || ''
  }

  getLocalizedContent(lang: string): string {
    const localizedContent = this.localizedContents.find(it => it.lang === lang)
    return localizedContent?.content || this.defaultContent
  }

  getLocalizedHtmlContent(lang: string): string {
    const localizedContent = this.localizedContents.find(it => it.lang === lang)
    return localizedContent?.htmlContent || this.defaultContent
  }
}

export default Notification
