import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { IdProvider } from '@radix-ui/react-id'
import styled, { keyframes } from 'styled-components'
import { breakpoints } from '../../../theme/breakpoints'

export interface ISideMenuProps {
  isOpen?: boolean
  onOpen: (value: boolean) => void
}


const DefaultSideMenu: React.FC<ISideMenuProps> = ({ isOpen, onOpen, children }) => {
  return (
    <IdProvider>
      <Dialog.Root open={isOpen} onOpenChange={onOpen}>
        <Overlay />
        <Container>
          <Header>
            <Dialog.Close>
              <CloseButton>X</CloseButton>
            </Dialog.Close>
          </Header>
          <Wrapper>
            {children}
          </Wrapper>
        </Container>
      </Dialog.Root>
    </IdProvider>
  )
}

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 }
})

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'scale(.30)' },
  '100%': { opacity: 1, transform: 'scale(1)' }
})


const Overlay = styled(Dialog.Overlay)`
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  animation: ${overlayShow} 300ms cubic-bezier(0.16, 1, 0.3, 1);
`

const Container = styled(Dialog.Content)`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 30;
  animation: ${contentShow} 300ms cubic-bezier(0.16, 1, 0.3, 1);


  @media ${breakpoints.tablet} {
    width: 50%;
  }

  @media ${breakpoints.laptop} {
    width: 33%;
  }

  @media ${breakpoints.laptopM} {
    width: 25%;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-bottom: solid 1px #ececec;
  padding: 1rem 2rem;
  min-height: 8rem;
`

const CloseButton = styled.div`
  border: 1px solid ${({ theme }) => theme.color.lightGrey};
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  display: flex;
  height: calc(100% - 10rem);
  flex-direction: column;
  row-gap: 1.5rem;
  margin-top: 2rem;
`


export default DefaultSideMenu
