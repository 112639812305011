import { GamePlatform } from '../../domain/models'

const prettyGamePlatform = (value: GamePlatform | string): string => {
  switch (value) {
    case GamePlatform.PC:
      return 'PC'
    case GamePlatform.GAMEBOY_COLOR:
      return 'GBA'
    case GamePlatform.NINTENDO_DS:
      return 'DS'
    case GamePlatform.NINTENDO_3DS:
      return '3DS'
    case GamePlatform.GAMECUBE:
      return 'GameCube'
    case GamePlatform.WII:
      return 'Wii'
    case GamePlatform.WII_U:
      return 'Wii U'
    case GamePlatform.SWITCH:
      return 'Switch'
    case GamePlatform.PS2:
      return 'PS2'
    case GamePlatform.PS3:
      return 'PS3'
    case GamePlatform.PS4:
      return 'PS4'
    case GamePlatform.PS5:
      return 'PS5'
    case GamePlatform.XBOX:
      return 'Xbox'
    case GamePlatform.XBOX_360:
      return 'Xbox 360'
    case GamePlatform.XBOX_ONE:
      return 'Xbox One'
    case GamePlatform.XBOX_SERIES:
      return 'Xbox Series X/S'
    default:
      return 'UNKNOW'
  }
}


export {
  prettyGamePlatform
}
