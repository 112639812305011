import React from 'react'

import { IAuthProvider, IGeolocationProvider, ISwappyProvider } from '../../domain/providers'

export interface IAppProviderContext {
  authProvider?: IAuthProvider
  swappyProvider?: ISwappyProvider
  isAuth: boolean
  setIsAuth: (value: boolean) => void
  geolocationProvider?: IGeolocationProvider
}


export const AppProviderContext = React.createContext<IAppProviderContext>({
  isAuth: false,
  setIsAuth: () => console.warn('setIsAuth is not implemented in the current context !')
})

export const useAppProviderContext = (): IAppProviderContext => React.useContext(AppProviderContext)
