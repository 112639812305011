import styled from 'styled-components'

interface IVisible {
  isVisible: boolean
}

export default styled.div<IVisible>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  margin-top: 0.5rem;
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: ${({ theme }) => theme.size.smaller};
  color: ${({ theme }) => theme.color.red};
  min-height: 1.1rem;
`
