import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { FormattedMessage } from 'react-intl'
import styled, { css, keyframes } from 'styled-components'
import Link from 'next/link'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import routes from '../../core/application/routes'
import { FaBellIcon, FaEllipsisVIcon } from '../icons'
import { useUserContext } from '../../core/application/contexts'
import { USER_AVATAR_PLACEHOLDER } from '../../core/constants'
import { RoundImage } from '../common/images'
import { useNotificationContext } from '../../core/application/contexts/NotificationContext'

const MobileNavbar = () => {
  const router = useRouter()
  const { user, removeUser } = useUserContext()
  const { notificationCount, setNotificationCount } = useNotificationContext()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleLogout = async () => {
    setIsOpen(false)
    setNotificationCount(0)
    removeUser()
    await router.replace(routes.LOGIN)
  }


  // close mobile menu when windows is resizing a lot
  useEffect(() => {
    const handleWindowResize = () => {
      const { innerWidth } = window
      if (innerWidth > 768 && isOpen) {
        setIsOpen(false)
      }
    }

    window.addEventListener('resize', handleWindowResize, { passive: true })
    return () => {
      clearAllBodyScrollLocks()
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [isOpen])

  // Close Mobile Navbar Menu when route change
  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false)
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events, isOpen])


  // block body scroll when Mobile Navbar Menu is Open
  useEffect(() => {
    const targetElement = document.querySelector('#dropdown-menu')
    if (isOpen && targetElement) {
      disableBodyScroll(targetElement)
    } else {
      clearAllBodyScrollLocks()
    }
  }, [isOpen])


  return (
    <>
      <Container id='mobile-navbar'>
        <LogoWrapper>
          <Link href={routes.INDEX}>
            <a>
              <img src='/images/logo.png' alt='swappy logo' />
              <span>Swappy</span>
            </a>
          </Link>
        </LogoWrapper>
        <IconWrapper onClick={() => setIsOpen(!isOpen)}>
          <FaEllipsisVIcon customClass='black' size={{ height: 2.5, width: 2.5 }} />
        </IconWrapper>
      </Container>
      {isOpen &&
        <Overlay onClick={() => setIsOpen(false)}>
          <MenuContainer id='dropdown-menu'>
            <MenuWrapper>
              <NavbarItem disabled>
                <FormattedMessage id='Navbar.Items.Help' />
              </NavbarItem>
              {user !== undefined ?
                <>
                  <NavbarItem current={router.asPath === routes.LIBRARY}>
                    <Link href={routes.LIBRARY} passHref>
                      <a>
                        <FormattedMessage id='Navbar.Items.Library' />
                      </a>
                    </Link>
                  </NavbarItem>
                  <NavbarItem current={router.asPath === routes.WISHLIST}>
                    <Link href={routes.WISHLIST} passHref>
                      <a>
                        <FormattedMessage id='Navbar.Items.Wishlist' />
                      </a>
                    </Link>
                  </NavbarItem>
                  <NavbarItem current={router.asPath === routes.CHAT}>
                    <Link href={routes.CHAT} passHref>
                      <a>
                        <FormattedMessage id='Navbar.Items.Chat' />
                      </a>
                    </Link>
                  </NavbarItem>
                  <NavbarItem onClick={handleLogout}>
                    <a>
                      <FormattedMessage id='NavbarDropdown.Item.Logout' />
                    </a>
                  </NavbarItem>
                  <BottomSection>
                    <BottomSectionItem>
                      <Link href={routes.NOTIFICATIONS} passHref>
                        <a>
                          <FaIconWrapper>
                            <FaBellIcon customClass='black' size={{ width: 2.5, height: 2.5 }} />
                            <PurpleDot visible={notificationCount > 0} />
                          </FaIconWrapper>
                        </a>
                      </Link>
                    </BottomSectionItem>
                    <BottomSectionItem>
                      <Link href={routes.ACCOUNT} passHref>
                        <UserAccountItem current={router.asPath === routes.ACCOUNT}>
                          <a>
                            {user.avatar ?
                              <RoundImage src={user.avatar} width={54} height={54} alt={user.username} /> :
                              <img src={USER_AVATAR_PLACEHOLDER} alt={user.username} />
                            }
                          </a>
                        </UserAccountItem>
                      </Link>
                    </BottomSectionItem>
                  </BottomSection>
                </> :
                <>
                  <NavbarItem current={router.asPath === routes.LOGIN}>
                    <Link href={routes.LOGIN} passHref>
                      <a>
                        <FormattedMessage id='Navbar.Items.Login' />
                      </a>
                    </Link>
                  </NavbarItem>
                  <NavbarItem current={router.asPath === routes.REGISTRATION}>
                    <Link href={routes.REGISTRATION} passHref>
                      <a>
                        <FormattedMessage id='Navbar.Items.Registration' />
                      </a>
                    </Link>
                  </NavbarItem>
                </>
              }
            </MenuWrapper>
          </MenuContainer>
        </Overlay>
      }
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${({ theme }) => theme.color.lightGrey};
  min-height: 8rem;
  padding-right: 1rem;
`

const LogoWrapper = styled.div`
  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.color.black};
  }


  img {
    max-height: 5.5rem;
  }

  span {
    font-size: 20px;
    font-weight: bold;
  }
`

const IconWrapper = styled.div`
  cursor: pointer;
`
const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 }
})


const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 8rem;
  z-index: 10;
  animation: ${overlayShow} 300ms cubic-bezier(0.16, 1, 0.3, 1);
`

const MenuContainer = styled.div`
  position: fixed;
  top: 8rem;
  z-index: 20;
  background-color: white;
  width: 100%;
  padding: 2rem 1rem;
`

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 1rem;
`

interface IItemsProps {
  disabled?: boolean
  current?: boolean
}


const NavbarItem = styled.div<IItemsProps>`
  font-weight: ${({ theme }) => theme.weight.semi};
  font-size: ${({ theme }) => theme.size.notNormal};
  color: ${({ theme, disabled }) => disabled ? theme.color.grey : theme.color.black};

  a {
    text-decoration: none;
    color: ${({ theme, disabled }) => disabled ? theme.color.grey : theme.color.black};
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
    pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
  }

  ${({ current }) => current && css`
    &::before {
      content: '';
      background-color: ${({ theme }) => theme.color.primary};
      border-radius: 50%;
      height: 0.8rem;
      width: 0.8rem;
      position: absolute;
      margin-left: -15px;
      margin-top: 9px;
    }
  `};
`

const BottomSection = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  & > div:first-child {
    border-right: solid 1px ${({ theme }) => theme.color.lightGrey};
  }

`

const BottomSectionItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface IPurpleDotProps {
  visible: boolean
}

const FaIconWrapper = styled.div`
  position: relative;
`

const PurpleDot = styled.div<IPurpleDotProps>`
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 50%;
  height: 1px;
  width: 1px;
  position: absolute;
  top: -0.45px;
  left: 25px;
  visibility: hidden;
  transition: transform 200ms ease-out;

  ${({ visible }) => visible && css`
    transform: scale(11);
    visibility: visible;
  `};
`

const UserAccountItem = styled.div<IItemsProps>`
  width: 5rem;
  height: 5rem;

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 3px solid ${({ theme, current }) => current ? theme.color.primary : theme.color.lightGrey} !important;

  }
`

export default MobileNavbar
