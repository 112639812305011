import {
  Channel,
  Game,
  GameResult,
  Message,
  Notification,
  Offer,
  PublicUser,
  User,
  UserGame
} from '../../domain/models'
import {
  IChannelData,
  IChannelMessageData, IChannelUserData,
  IGameData,
  INotificationData,
  IOfferData,
  IOfferProposalData,
  IPublicUserData,
  IResultGameData,
  IUserData,
  IUserGameData,
  IUserWishData
} from './index'
import UserWish from '../../domain/models/UserWish'
import { parseGameCondition } from '../../domain/models/GameCondition'
import { parseOfferStatus } from '../../domain/models/OfferStatus'
import { parseGamePlatform } from '../../domain/models/GamePlatform'
import UserLocation from '../../domain/models/UserLocation'
import OfferAction from '../../domain/models/OfferAction'
import { parseOfferActionCode } from '../../domain/models/OfferActionCode'
import { IOfferProposal } from '../../domain/models/Offer'
import { ChannelLastMessage } from '../../domain/models/Channel'


class Parser {

  static offerProposalFromOfferProposalData({ user, game, wish }: IOfferProposalData): IOfferProposal {
    return {
      user: {
        id: user.id,
        username: user.username
      },
      game: {
        id: game.id,
        gameId: game.gameId,
        name: game.name,
        platform: game.platform
      },
      wish: {
        id: wish.id,
        gameId: wish.gameId,
        name: wish.name,
        platform: wish.platform
      }
    }
  }

  static offerFromOfferData(offerData: IOfferData): Offer {
    const actions = offerData.actions.map(it => {
      const code = parseOfferActionCode(it.code)
      return new OfferAction(
        it.userId,
        new Date(it.date),
        code
      )
    })

    const status = parseOfferStatus(offerData.status)
    return new Offer({
      id: offerData.id,
      date: new Date(offerData.date),
      status,
      distance: offerData.distance,
      proposals: offerData.proposals.map(it => Parser.offerProposalFromOfferProposalData(it)),
      actions: actions
    })
  }

  static gameResultFromGameResultData(gameResultData: IResultGameData): GameResult {
    const platforms = gameResultData.platforms.map(it => parseGamePlatform(it))

    return new GameResult({
      externalId: gameResultData.externalId,
      name: gameResultData.name,
      summary: gameResultData.summary,
      releaseDate: gameResultData.firstReleaseDate ? new Date(gameResultData.firstReleaseDate) : undefined,
      coverUrl: gameResultData.cover,
      platforms
    })
  }

  static gameFromGameData(gameData: IGameData): Game {
    return new Game({
      id: gameData.id,
      externalId: gameData.externalId,
      name: gameData.name,
      summary: gameData.summary,
      releaseDate: gameData.firstReleaseDate ? new Date(gameData.firstReleaseDate) : undefined,
      coverUrl: gameData.cover,
      platform: parseGamePlatform(gameData.platform)
    })
  }

  static userGameFromUserGamData(userGameData: IUserGameData): UserGame {
    const game = Parser.gameFromGameData(userGameData.game)

    return {
      id: userGameData.id,
      platform: parseGamePlatform(userGameData.platform),
      game,
      condition: parseGameCondition(userGameData.condition)
    }
  }

  static userFromUserData(userData: IUserData): User {
    return new User({
      id: userData.id,
      createdAt: userData.createdAt,
      firstName: userData.firstName,
      lastName: userData.lastName,
      username: userData.username,
      avatar: userData.avatar,
      email: userData.email,
      range: userData.range,
      bio: userData.bio,
      city: userData.city,
      country: userData.country,
      favoritePlatform: userData.favoritePlatform ? parseGamePlatform(userData.favoritePlatform) : undefined,
      location: userData.location ? new UserLocation(userData.location) : undefined
    })
  }

  static publicUserFromPublicUserData(publicUserData: IPublicUserData): PublicUser {
    return new PublicUser({
      id: publicUserData.id,
      createdAt: publicUserData.createdAt,
      firstName: publicUserData.firstName,
      lastName: publicUserData.lastName,
      username: publicUserData.username,
      avatar: publicUserData.avatar,
      bio: publicUserData.bio,
      city: publicUserData.city,
      country: publicUserData.country
    })
  }

  static UserWishFromUserWishData(UserWishData: IUserWishData, offers?: Array<Offer>): UserWish {
    const game = Parser.gameFromGameData(UserWishData.game)

    return new UserWish({
      id: UserWishData.id,
      minNote: UserWishData.minNote,
      range: UserWishData.range,
      platform: parseGamePlatform(UserWishData.platform),
      game,
      offers: offers || [],
      conditions: UserWishData.conditions.map(it => parseGameCondition(it))
    })
  }

  static notificationFromNotificationData(notificationData: INotificationData): Notification {
    return new Notification({
      id: notificationData.id,
      localizedContents: notificationData.localizedContents,
      date: new Date(notificationData.createdAt),
      isNew: !notificationData.read
    })
  }

  static messageFromMessageData(messageData: IChannelMessageData): Message {
    return new Message({
      id: messageData.id,
      userId: messageData.userId,
      createdAt: new Date(messageData.createdAt),
      content: messageData.content
    })
  }

  static channelFromChannelData(channelData: IChannelData): Channel {
    const users: Array<IChannelUserData> = channelData.users.map(it => ({
      userId: it.userId,
      username: it.username,
      firstName: it.firstName,
      lastName: it.lastName,
      avatar: it.avatar
    }))

    const messages = channelData.messages.map(it => Parser.messageFromMessageData(it))

    let lastMessage: ChannelLastMessage | undefined = undefined
    if (channelData.lastMessage) {
      lastMessage = {
        userId: channelData.lastMessage.userId,
        createdAt: new Date(channelData.lastMessage.createdAt),
        content: channelData.lastMessage.content,
        isRead: channelData.lastMessage.isRead
      }
    }

    return new Channel({
      id: channelData.id,
      createdAt: new Date(channelData.createdAt),
      users,
      messages,
      lastMessage
    })
  }
}

export default Parser
