export enum GameCondition {
  NEW = 'NEW',
  VERY_GOOD = 'VERY_GOOD',
  GOOD = 'GOOD',
  AVERAGE = 'AVERAGE',
  UNKNOWN = 'UNKNOWN'
}

const isGameCondition = (value: string): value is GameCondition => {
  return Object.values(GameCondition).includes(value as GameCondition)
}

const parseGameCondition = (value: string): GameCondition => {
  switch (value) {
    case 'new':
    case 'NEW':
      return GameCondition.NEW
    case 'very_good':
    case 'VERY_GOOD':
      return GameCondition.VERY_GOOD
    case 'average':
    case 'AVERAGE':
      return GameCondition.AVERAGE
    case 'good':
    case 'GOOD':
      return GameCondition.GOOD
    default:
      return GameCondition.UNKNOWN
  }
}

export {
  isGameCondition,
  parseGameCondition
}
