export interface IPublicUserProps {
  id: string
  firstName: string
  createdAt: string
  lastName: string
  username: string
  avatar?: string
  bio?: string
  country?: string
  city?: string
}

class PublicUser {
  id: string
  firstName: string
  lastName: string
  username: string
  createdAt: Date
  avatar?: string
  bio?: string
  country?: string
  city?: string

  constructor({ id, firstName, lastName, username, createdAt, avatar, bio, country, city }: IPublicUserProps) {
    this.id = id
    this.firstName = firstName
    this.lastName = lastName
    this.username = username
    this.createdAt = new Date(createdAt)
    this.avatar = avatar
    this.bio = bio
    this.country = country
    this.city = city
  }
}

export default PublicUser
