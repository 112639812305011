import React, { useEffect } from 'react'
import styled from 'styled-components'
import * as Dialog from '@radix-ui/react-dialog'
import { useRouter } from 'next/router'
import { IdProvider } from '@radix-ui/react-id'

export interface IDefaultModalProps {
  isOpen?: boolean
  onOpen: (value: boolean) => void
}


const DefaultModal: React.FC<IDefaultModalProps> = ({ onOpen, isOpen, children }) => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      onOpen(false)
    }
    router.events.on('routeChangeStart', handleRouteChange)


    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <IdProvider>
      <Dialog.Root open={isOpen} onOpenChange={onOpen}>
        <Dialog.Portal>
          <Overlay />
          <ModalContent>
            {children}
          </ModalContent>
        </Dialog.Portal>
      </Dialog.Root>
    </IdProvider>
  )
}


const Overlay = styled(Dialog.Overlay)`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
`


const ModalContent = styled(Dialog.Content)`
  min-width: 32rem;
  position: fixed;
  border-radius: 0.4rem;
  border: solid 1px ${({ theme }) => theme.color.lightGrey};
  background: ${({ theme }) => theme.color.background};
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 21;
`


export default DefaultModal
