import React from 'react'
import LoginModal from './modals/LoginModal'
import { useErrorModalContext, useModalContext } from '../../core/application/contexts'
import { Modal } from '../../core/application/types'
import { ErrorModal } from './modals'

const ModalManager = () => {
  const { setErrorModalMessage, errorModalMessage, setIsOpen: setErrorModal, isOpen: errorModal } = useErrorModalContext()

  const { currentModal, closeCurrentModal } = useModalContext()
  const loginModalHandler = (value: boolean) => {
    if (!value) {
      closeCurrentModal()
    }
  }

  const errorModalHandler = (value: boolean) => {
    if (!value) {
      setErrorModalMessage('')
    }
  }


  return (
    <>
      <LoginModal
        isOpen={currentModal === Modal.LOGIN_MODAL}
        onOpen={loginModalHandler}
      />
      {errorModalMessage &&
        <ErrorModal
          onOpen={errorModalHandler}
          isOpen={errorModal}
          errorMessage={errorModalMessage}
        />
      }
    </>
  )
}

export default ModalManager
