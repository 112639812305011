import React from 'react'
import { ToastMode } from '../types'


export interface OpenToastParams {
  title?: string
  description?: string
  mode?: ToastMode
}

export interface IToastContext {
  displayToast: (params: OpenToastParams) => void
  closeToast: () => void
}


export const ToastContext = React.createContext<IToastContext>({
  displayToast: () => {
    console.warn(
      'displayToast method not implemented in current context'
    )
  },
  closeToast: () => {
    console.warn(
      'closeToast method not implemented in current context'
    )
  }
})

export const useToastContext = (): IToastContext => React.useContext(ToastContext)
