import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../../theme/breakpoints'
import CustomLabel from './CustomLabel'
import { ErrorMessageWrapper } from './index'

export interface ITextInputProps {
  id: string
  label?: string
  placeholder?: string
  autoComplete?: string
  type?: string
  hint?: string
  error?: string | null
  value?: string
  autoFocus?: boolean
  onChange?: (value: string) => void
}

const SimpleTextInput: React.FC<ITextInputProps> = ({
  id,
  label,
  placeholder,
  type = 'text',
  autoComplete,
  value,
  onChange,
  autoFocus,
  error
}) => {
  return (
    <Container>
      {label && <CustomLabel htmlFor={id}>{label}</CustomLabel>}
      <CustomInput
        id={id} name={id} type={type}
        placeholder={placeholder} autoComplete={autoComplete}
        value={value}
        autoFocus={autoFocus}
        onChange={onChange ? (event) => onChange(event.target.value) : undefined}
      />
      <ErrorMessageWrapper isVisible={error !== undefined}>{error}</ErrorMessageWrapper>
    </Container>
  )
}

export default SimpleTextInput

const Container = styled.div`
  min-width: 25rem;

  @media ${breakpoints.mobileL} {
    min-width: 30rem;
  }
`

const CustomInput = styled.input`
  border: solid 1px #e6e6e6;
  width: 100%;
  padding: 1.8rem;
  border-radius: 0.4rem;
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: ${({ theme }) => theme.size.mobileMinSize};

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.size.small};
    ::placeholder {
      font-size: ${({ theme }) => theme.size.small};
    }
  }

  ::placeholder {
    font-weight: ${({ theme }) => theme.weight.normal};
    font-size: ${({ theme }) => theme.size.mobileMinSize};
    color: ${({ theme }) => theme.color.grey};
  }
`
