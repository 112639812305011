import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import * as Dialog from '@radix-ui/react-dialog'
import Link from 'next/link'
import DefaultModal from './DefaultModal'
import { GameCondition, GamePlatform } from '../../../core/domain/models'
import ModalWrapper from './ModalWrapper'
import { FlatButton, FullSizeSubmitButton } from '../../common/buttons'
import routes from '../../../core/application/routes'
import { prettyGamePlatform } from '../../../core/application/utils/gamePlatformUtils'


export interface ILibraryGameDetailsModal {
  isOpen?: boolean
  onOpen: (value: boolean) => void
  onUpdate: (userGameId: string, gameId: string) => void
  onDelete: (userGameId: string, gameId: string) => void
  userGameId: string
  game: {
    gameId: string
    name: string
    cover?: string
    platform: GamePlatform
    condition: GameCondition
  }
}

const LibraryGameDetailsModal: React.FC<ILibraryGameDetailsModal> = ({ userGameId, isOpen, onOpen, game, onUpdate, onDelete }) => {
  const gameRoute = routes.GAME_DETAILS.replace('[gameId]', game.gameId)

  return (
    <DefaultModal onOpen={onOpen} isOpen={isOpen}>
      <Dialog.Description asChild>
        <ModalWrapper>
          <ModalContainer>
            <GameInfoWrapper>
              <GameName>
                {game.name}
              </GameName>
              <ConsoleName>
                {prettyGamePlatform(game.platform)} - <FormattedMessage id={`Common.GameCondition.${game.condition}`} /> {}
              </ConsoleName>
            </GameInfoWrapper>
            <ButtonListWrapper>
              <Link href={gameRoute}>
                <a>
                  <FullSizeSubmitButton>
                    <FormattedMessage id='LibraryGameDetailModal.ButtonListWrapper.RedirectLabel' />
                  </FullSizeSubmitButton>
                </a>
              </Link>
              <EditButton onClick={() => onUpdate(userGameId, game.gameId)}>
                <FormattedMessage id='LibraryGameDetailModal.ButtonListWrapper.EditButton' />
              </EditButton>
              <RemoveButton onClick={() => onDelete(userGameId, game.gameId)}>
                <FormattedMessage id='LibraryGameDetailModal.ButtonListWrapper.RemoveButton' />
              </RemoveButton>
            </ButtonListWrapper>
          </ModalContainer>
        </ModalWrapper>
      </Dialog.Description>
    </DefaultModal>

  )
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5rem;
  justify-content: flex-end;
  flex: 1;
`

const CoverWrapper = styled.figure`
  display: block;
  position: relative;
  padding-top: 125%;
  height: 8rem;

  img {
    height: 100%;
  }

`

const GameInfoWrapper = styled.div`
  margin-top: 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-height: 12rem;
`

const GameName = styled.div`
  font-weight: ${({ theme }) => theme.weight.bold};
  font-size: 1.8rem;
  color: ${({ theme }) => theme.color.black};
`

const ConsoleName = styled.div`
  font-weight: ${({ theme }) => theme.weight.semi};
  font-size: ${({ theme }) => theme.size.small};
  color: ${({ theme }) => theme.color.yetAnotherGrey};
`

const EditButton = styled(FlatButton)`
  width: 100%;
  background-color: transparent;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.anotherGrey};
  color: ${({ theme }) => theme.color.anotherGrey};
`

const RemoveButton = styled(FlatButton)`
  width: 100%;
  background-color: transparent;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.red};
  color: ${({ theme }) => theme.color.red};
`

const ButtonListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`

export default LibraryGameDetailsModal

