import Message from './Message'

export interface ChannelUser {
  userId: string
  username: string
  firstName: string
  lastName: string
  avatar?: string
}

export interface ChannelLastMessage {
  userId: string
  createdAt: Date
  content: string
  isRead: boolean
}

export interface IChannelProps {
  id: string
  createdAt: Date
  users: Array<ChannelUser>
  messages: Array<Message>
  lastMessage?: ChannelLastMessage
}

class Channel {
  id: string
  createdAt: Date
  users: Array<ChannelUser>
  messages: Array<Message>
  lastMessage?: ChannelLastMessage

  constructor({ id, createdAt, users, messages, lastMessage }: IChannelProps) {
    this.id = id
    this.createdAt = createdAt
    this.users = users
    this.messages = messages
    this.lastMessage = lastMessage
  }

  getUser(userId: string): ChannelUser {
    if (this.users[0].userId === userId) {
      return this.users[0]
    }
    return this.users[1]
  }

  getSwapper(userId: string): ChannelUser {
    if (this.users[0].userId === userId) {
      return this.users[1]
    }
    return this.users[0]
  }
}

export default Channel
