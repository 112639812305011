export interface Dictionary<T> {
  [Key: string]: T
}

export interface IReactSliceComponent {
  slice: Dictionary<any>
}

export enum Modal {
  LOGIN_MODAL = 'LOGIN_MODAL',
  REGISTER_MODAL = 'REGISTER_MODAL'
}


export interface ILocation {
  city: string
  country: string
  cityCode: string
  latitude: number
  longitude: number
}

export interface IAppUser {
  id: string
  createdAt: Date
  email: string
  firstName: string
  lastName: string
  username: string
  picture?: string
  location?: ILocation
}

export interface IUserSummary {
  userId: string
  username: string
  avatar?: string | null
  city?: string | null
  country?: string | null
}


export interface IRadioGroupItemOption {
  value: any
  label: string
}

export interface ICheckboxGroupItemOption {
  value: any
  label: string
}

export interface IUserGeoLocation {
  latitude: number
  longitude: number
  cityCode: string
  city: string
  country: string
}

export enum ToastMode {
  SUCCESS,
  INFO,
  ERROR,
  WARN,
}
