import React from 'react'
import * as RadioGroup from '@radix-ui/react-radio-group'
import styled from 'styled-components'
import { IdProvider } from '@radix-ui/react-id'
import { IRadioGroupItemOption } from '../../../core/application/types'

export interface ICustomRadioGroupProps {
  groupId: string
  groupLabel: string
  options: Array<IRadioGroupItemOption>
  currentValue?: any
  onChange: (value: any) => void
}

const CustomRadioGroup: React.FC<ICustomRadioGroupProps> = ({
  groupId,
  groupLabel,
  onChange,
  currentValue,
  options
}) => {
  return (
    <IdProvider>
      <RadioGroup.Root value={currentValue} onValueChange={onChange}>
        <GroupLabel>
          {groupLabel}
        </GroupLabel>
        <Container>
          {options.map((it, index) => (
            <RadioItemWrapper key={index}>
              <CustomRadioItem id={`${groupId}-${index}`} value={it.value}>
                <CustomIndicator />
              </CustomRadioItem>
              <Label selected={currentValue === it.value} htmlFor={`${groupId}-${index}`}>{it.label}</Label>
            </RadioItemWrapper>
          ))}
        </Container>
      </RadioGroup.Root>
    </IdProvider>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 1rem;
  flex-wrap: wrap;
`

const GroupLabel = styled.div`
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: ${({ theme }) => theme.size.small};
  color: ${({ theme }) => theme.color.black};
  margin-bottom: 1rem;
`

const RadioItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  flex-basis: 50%;
`


const CustomRadioItem = styled(RadioGroup.Item)`
  background-color: ${({ theme }) => theme.color.white};
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  border: solid 1px ${({ theme }) => theme.color.black};

  &[data-state='checked'] {
  }
`

const CustomIndicator = styled(RadioGroup.Indicator)`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.black};
  }
`

interface ILabel {
  selected?: boolean
}

const Label = styled.label<ILabel>`
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: ${({ theme }) => theme.size.small};
  color: ${({ theme, selected }) => selected ? theme.color.black : theme.color.black};
  text-transform: uppercase;
`

export default CustomRadioGroup
