import { DefaultTheme } from 'styled-components'

declare module 'styled-components' {
  export interface DefaultTheme {
    color: {
      primary: string
      secondary: string
      red: string
      green: string
      white: string
      black: string
      grey: string
      anotherGrey: string
      yetAnotherGrey: string
      darkGrey: string
      lightGrey: string
      lighterGrey: string
      gold: string
      background: string
      secondaryBackground: string
    }
    size: {
      normal: string
      notNormal: string
      mobileNormal: string
      title: string
      subtitle: string
      small: string
      smaller: string
      bigger: string
      mobileMinSize: string
    }
    font: {
      default: string
      gotham: string
    }
    weight: {
      light: number
      normal: number
      semi: number
      bold: number
    },
    padding: {
      pageHorizontalPadding: string
      smallPageHorizontalPadding: string
      mobilePageHorizontalPadding: string
      smallMobilePageHorizontalPadding: string
    }
  }
}

export const commonTheme: DefaultTheme = {
  color: {
    primary: '#8863e8',
    secondary: '#8863e8',
    red: '#e86363',
    green: '#87d96a',
    white: '#ffffff',
    black: '#000000',
    grey: '#b0b0b0',
    anotherGrey: '#b6b6b6',
    yetAnotherGrey: '#bdbdbd',
    darkGrey: '#9b9b9b',
    lightGrey: '#e9e9e9',
    lighterGrey: '#f7f7f7',
    gold: '#eec349',
    background: '#ffffff',
    secondaryBackground: '#fbfafc'
  },
  font: {
    default: 'Roboto, sans-serif',
    gotham: 'Gotham, serif'
  },
  size: {
    normal: '1.4rem',
    notNormal: '2rem',
    mobileNormal: '1.6rem',
    title: '3.6rem',
    subtitle: '4.8rem',
    small: '1.2rem',
    smaller: '1rem',
    bigger: '7.8rem',
    mobileMinSize: '1.6rem'
  },
  weight: {
    light: 300,
    normal: 400,
    semi: 500,
    bold: 700
  },
  padding: {
    pageHorizontalPadding: '11.5rem',
    smallPageHorizontalPadding: '4rem',
    mobilePageHorizontalPadding: '2.5rem',
    smallMobilePageHorizontalPadding: '2rem'
  }
}
