import React, { useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import * as Dialog from '@radix-ui/react-dialog'
import { IdProvider } from '@radix-ui/react-id'
import TextInput from '../../common/inputs/TextInput'
import LargeSubmitButton from '../../common/buttons/LargeSubmitButton'
import routes from '../../../core/application/routes'
import ModalWrapper from './ModalWrapper'

export interface ILoginModalProps {
  isOpen?: boolean
  onOpen: (value: boolean) => void
}

const LoginModal: React.FC<ILoginModalProps> = ({ isOpen, onOpen }) => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      onOpen(false)
    }
    router.events.on('routeChangeStart', handleRouteChange)


    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
    // eslint-disable-next-line
  }, [])



  return (
    <IdProvider>
      <Dialog.Root open={isOpen} onOpenChange={onOpen}>
        <Dialog.Portal>
          <Overlay />
          <ModalContainer>
            <ModalHeader>
              Log in and start sharing <br />
              your games to play more
            </ModalHeader>
            <Dialog.Description asChild>
              <ModalWrapper>
                <FormContainer>
                  <TextInput id='username' autoComplete='username' placeholder='Username' />
                  <TextInput id='password' type='password' autoComplete='current-password' placeholder='Password' />
                  <LargeSubmitButton type='submit'>Login</LargeSubmitButton>
                </FormContainer>
                <BottomSection>
                  <Link href={routes.REGISTRATION} passHref>
                    <BottomLink>
                      Don’t have your account ? Sign up.
                    </BottomLink>
                  </Link>
                  <Link href={routes.RESET_PASSWORD} passHref>
                    <GreyLink>
                      Lost password ?
                    </GreyLink>
                  </Link>
                </BottomSection>
              </ModalWrapper>
            </Dialog.Description>
          </ModalContainer>
        </Dialog.Portal>
      </Dialog.Root>
    </IdProvider>
  )
}

export default LoginModal

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const ModalHeader = styled(Dialog.Title)`
  font-weight: ${({ theme }) => theme.weight.bold};
  font-size: ${({ theme }) => theme.size.notNormal};
  padding: 4rem 2.7rem;
  border-bottom: solid 1px ${({ theme }) => theme.color.lightGrey};
`

const ModalContainer = styled(Dialog.Content)`
  min-width: 32rem;
  min-height: 40rem;
  position: fixed;
  border-radius: 0.4rem;
  border: solid 1px ${({ theme }) => theme.color.lightGrey};
  background: ${({ theme }) => theme.color.background};
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`

const BottomSection = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const BottomLink = styled.a`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.size.smaller};
  font-style: italic;
  cursor: pointer;
  text-decoration: none;
`

const GreyLink = styled(BottomLink)`
  color: ${({ theme }) => theme.color.anotherGrey} !important;
`
