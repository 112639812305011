export enum OfferStatus {
  MATCH = 'MATCH',
  OFFER = 'OFFER',
  BLOCKED = 'BLOCKED',
  EXCHANGE = 'EXCHANGE',
  TERMINATED = 'TERMINATED',
  CANCELED = 'CANCELED',
  UNKNOWN = 'UNKNOWN'
}

const parseOfferStatus = (value: string): OfferStatus => {
  switch (value) {
    case 'MATCH':
      return OfferStatus.MATCH
    case 'OFFER':
      return OfferStatus.OFFER
    case 'EXCHANGE':
      return OfferStatus.EXCHANGE
    case 'TERMINATED':
      return OfferStatus.TERMINATED
    case 'CANCELED':
      return OfferStatus.CANCELED
    case 'BLOCKED':
      return OfferStatus.BLOCKED
    default:
      return OfferStatus.UNKNOWN
  }
}

export {
  parseOfferStatus
}
