import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'
import { getAuth } from 'firebase/auth'
import { IAuthProvider, IGeolocationProvider, ISwappyProvider } from '../../domain/providers'
import { firebaseConfig, NODE_ENV, SWAPPY_API_ENDPOINT } from '../../config'
import { SwappyProvider } from '../../data/swappy'
import { GeolocationProvider } from '../../data/geolocation'
import { FirebaseAuthProvider } from '../../data/firebase'

export interface IDependencies {
  authProvider: IAuthProvider
  swappyProvider: ISwappyProvider
  geolocationProvider: IGeolocationProvider
}


export interface IServerDependencies {
  swappyProvider: ISwappyProvider
  geolocationProvider: IGeolocationProvider
}

export const createDependencies = (): IDependencies => {
  const swappyProvider = new SwappyProvider(SWAPPY_API_ENDPOINT)
  const geolocationProvider = new GeolocationProvider()

  const app = initializeApp(firebaseConfig)
  if (app.name && typeof window !== 'undefined' && NODE_ENV === 'production') {
    getAnalytics(app)
    getPerformance(app)
  }

  const authProvider = new FirebaseAuthProvider(getAuth())

  return {
    authProvider,
    swappyProvider,
    geolocationProvider
  }
}


export const createServerDependencies = (): IServerDependencies => {
  const swappyProvider = new SwappyProvider(SWAPPY_API_ENDPOINT)
  const geolocationProvider = new GeolocationProvider()

  return {
    swappyProvider,
    geolocationProvider
  }
}
