import React from 'react'
import { Modal } from '../types'

export interface IModalContext {
  currentModal?: Modal
  setCurrentModal: (modal: Modal) => void
  closeCurrentModal: () => void
}

export const ModalContext = React.createContext<IModalContext>({
  setCurrentModal: () => {
    console.warn(
      'setCurrentModal method not implemented in current context'
    )
  },
  closeCurrentModal: () => {
    console.warn(
      'closeCurrentModal method not implemented in current context'
    )
  }
})

export const useModalContext = (): IModalContext =>
  React.useContext(ModalContext)
