import UserLocation from './UserLocation'
import { GamePlatform } from './GamePlatform'

export interface IUserProps {
  id: string
  createdAt: string
  firstName: string
  lastName: string
  username: string
  email: string
  avatar?: string
  range: number
  bio?: string
  country?: string
  city?: string
  location?: UserLocation
  favoritePlatform?: GamePlatform
}

class User {
  id: string
  firstName: string
  lastName: string
  username: string
  email: string
  createdAt: Date
  avatar?: string
  range: number
  bio?: string
  location?: UserLocation
  favoritePlatform?: GamePlatform
  country?: string
  city?: string

  constructor({
    id,
    firstName,
    lastName,
    username,
    email,
    createdAt,
    range,
    avatar,
    bio,
    location,
    city,
    country,
    favoritePlatform
  }: IUserProps) {
    this.id = id
    this.firstName = firstName
    this.lastName = lastName
    this.username = username
    this.email = email
    this.createdAt = new Date(createdAt)
    this.avatar = avatar
    this.range = range
    this.bio = bio || undefined
    this.location = location || undefined
    this.city = city || undefined
    this.country = country || undefined
    this.favoritePlatform = favoritePlatform
  }
}

export default User
