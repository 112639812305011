export interface IMessageProps {
  id: string
  userId: string
  createdAt: Date
  content: string
}

class Message {
  id: string
  userId: string
  createdAt: Date
  content: string

  constructor({ id, content, createdAt, userId }: IMessageProps) {
    this.id = id
    this.userId = userId
    this.createdAt = createdAt
    this.content = content
  }
}

export default Message
