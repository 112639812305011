import React, { useEffect, useState } from 'react'
import { useAppProviderContext, UserContext } from '../../core/application/contexts'
import { IAuthProvider, ISwappyProvider } from '../../core/domain/providers'
import { User } from '../../core/domain/models'
import { apiErrorHandler } from '../../core/domain/providers/errors'

const UserManager: React.FC = ({ children }) => {
  const { authProvider, swappyProvider, setIsAuth } = useAppProviderContext()
  const [userAuthId, setUserAuthId] = useState<string>()
  const [user, setUser] = useState<User>()
  const [userGamesCount, setUserGamesCount] = useState<number>(0)
  const [userWishCount, setUserWishCount] = useState<number>(0)
  const [userTerminatedOfferCount, setUserTerminatedOfferCount] = useState<number>(0)

  const logout = () => {
    setUser(undefined)
    setUserAuthId(undefined)
    authProvider && authProvider.logout()
  }

  useEffect(() => {
    const fetchCurrentUserData = async (userAuthId: string, authProvider: IAuthProvider, swappyProvider: ISwappyProvider) => {
      const authToken = await authProvider.getAuthToken()
      swappyProvider.addAuthorizationToken(authToken)
      try{
        setIsAuth(true)
        const currentUser = await swappyProvider.getCurrentUser()
        const userStats = await swappyProvider.getCurrentUserStats()
        setUserTerminatedOfferCount(userStats.terminatedOffers)
        setUserGamesCount(userStats.userGames)
        setUser(currentUser)
      } catch (err) {
        apiErrorHandler(err)
        setIsAuth(false)
      }
    }
    if (userAuthId && swappyProvider && authProvider) {
      fetchCurrentUserData(userAuthId, authProvider, swappyProvider)
    }
  }, [setIsAuth, swappyProvider, authProvider, userAuthId])

  useEffect(() => {
    if (authProvider && typeof window !== 'undefined') {
      authProvider.onAuthChanged((authData) => {
        setUserAuthId(authData.uid)
      })
    }

  }, [authProvider])


  return (
    <UserContext.Provider
      value={{
        userAuthId,
        setUserAuthId,
        user,
        setUser,
        userGames: userGamesCount,
        setUserGames :setUserGamesCount,
        userWishes: 0,
        setUserWishes: setUserWishCount,
        terminatedOffers: userTerminatedOfferCount,
        setTerminatedOffers: setUserTerminatedOfferCount,
        removeUser: logout
      }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserManager
