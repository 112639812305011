export default {
  INDEX: '/',
  LOGIN: '/login',
  REGISTRATION: '/registration',
  UPDATE_USER_EMAIL: '/me/updateEmail',
  UPDATE_USER_PASSWORD: '/me/updatePassword',
  RESET_PASSWORD: '/resetPassword',
  RESET_PASSWORD_CONFIRMATION: '/resetPasswordConfirmation',
  ACCOUNT: '/me/account',
  LIBRARY: '/me/library',
  CHAT: '/me/messages',
  WISHLIST: '/me/wishlist',
  USER_WISH_DETAIL: '/me/wishlist/[wishId]',
  USER_GAME_FORM: '/me/userGame/[gameId]',
  USER_WISH_FORM: '/me/userWish/[gameId]',
  NOTIFICATIONS: '/me/notification',
  GAME_DETAILS: '/games/[gameId]',
  USER_DETAILS: '/users/[userId]',
  NOT_FOUND_ERROR_PAGE: '/404'
}
