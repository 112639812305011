import React, { useEffect, useState } from 'react'

import { useAppProviderContext } from '../../core/application/contexts'
import { NotificationContext } from '../../core/application/contexts/NotificationContext'
import { ISwappyProvider } from '../../core/domain/providers'
import { delay } from '../../core/application/utils/timeoutUtils'


const NotificationManager: React.FC = ({ children }) => {
  const { swappyProvider, isAuth } = useAppProviderContext()
  const [notificationCount, setNotificationCount] = useState<number>(0)

  useEffect(() => {
    const fetchInitialNotificationCount = async (provider: ISwappyProvider) => {
      const notifications = await provider.getNotificationList()
      await delay(1000)
      setNotificationCount(notifications.filter(it => it.isNew).length)
    }
    if (isAuth && swappyProvider) {
      fetchInitialNotificationCount(swappyProvider)
    }

  }, [isAuth, swappyProvider])


  return (
    <NotificationContext.Provider
      value={{
        notificationCount,
        setNotificationCount
      }}>
      {children}
    </NotificationContext.Provider>
  )

}

export default NotificationManager
