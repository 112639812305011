export class AppProviderError extends Error {
  code: string
  domain: string

  constructor(message?: string, code?: string, domain?: string) {
    super(message)
    this.code = code || 'APP_ERROR'
    this.domain = domain || 'app'
  }

  get intlErrorKey(): string {
    return `Common.Errors.${this.code}`
  }
}

export const isAppProviderError = (error: any): error is AppProviderError => {
  return (typeof error === 'object') && (error.code !== undefined) && (error.message !== undefined) && (error.domain !== undefined)
}
