import { GamePlatform } from './GamePlatform'

export interface IGameProps {
  id: string
  externalId: string
  name: string
  platform: GamePlatform
  releaseDate?: Date
  summary?: string
  coverUrl?: string
}


class Game {
  id: string
  externalId: string
  name: string
  platform: GamePlatform
  releaseDate?: Date
  summary?: string
  coverUrl?: string

  constructor({ id, externalId, name, platform, releaseDate, summary, coverUrl }: IGameProps) {
    this.id = id
    this.externalId = externalId
    this.name = name
    this.platform = platform
    this.releaseDate = releaseDate
    this.summary = summary
    this.coverUrl = coverUrl
  }

}

export default Game
