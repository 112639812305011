import axios from 'axios'
import * as Sentry from '@sentry/nextjs'
import { AppProviderError } from '../../data/errors'
import { User } from '../models'

export enum FirebaseAuthErrorCode {
  WEAK_PASSWORD = 'WEAK_PASSWORD',
  UNKNOWN_FIREBASE_ERROR = 'UNKNOWN_FIREBASE_ERROR',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  EMAIL_ALREADY_USED = 'EMAIL_ALREADY_USED',
  WRONG_PASSWORD = 'WRONG_PASSWORD',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  USER_NOT_LOGGED = 'USER_NOT_LOGGED'
}

export enum RegisterErrorCode {
  EMAIL_NOT_VALID = 'EMAIL_NOT_VALID',
  EMAIL_NOT_AVAILABLE = 'EMAIL_NOT_AVAILABLE'
}

class SwappyProviderError extends AppProviderError {
  constructor(message: string, code?: RegisterErrorCode) {
    super(message, code, 'swappy')
  }
}

class AuthProviderError extends AppProviderError {
  constructor(message: string, code?: FirebaseAuthErrorCode) {
    super(message, code, 'auth')
  }
}

const isAuthProviderError = (error: any): error is AuthProviderError => {
  return (typeof error === 'object') && (error.code !== undefined) && (error.message !== undefined) && (error.domain === 'auth')
}

const isSwappyProviderError = (error: any): error is AppProviderError => {
  return (typeof error === 'object') && (error.code !== undefined) && (error.message !== undefined) && (error.domain === 'SwappyProvider')
}


const apiErrorHandler = (error: any, user?: User): AppProviderError => {
  let code: string = 'SWAPPY_API_ERROR'
  let message: string = 'Unknown Api Error'
  if (axios.isAxiosError(error)) {
    code = error.response?.data.code
    message = error.response?.data.message
  }
  console.error('code > ', code)
  console.error('message > ', message)
  const appProviderError = new AppProviderError(message, code, 'SwappyClient')
  Sentry.captureException(appProviderError, {
    user: user ? {
      username: user.username,
      id: user.id,
      email: user.email
    } : undefined
  })
  return appProviderError
}


export {
  SwappyProviderError,
  AuthProviderError,
  apiErrorHandler,
  isAuthProviderError,
  isSwappyProviderError
}
