import styled from 'styled-components'

export interface IFaIconContainerProps {
  size?: {
    height: number
    width: number
  }
}

const FaIconContainer = styled.div<IFaIconContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    height: ${({ size }) => size ? `${size.height}rem` : '1.2rem'};
    width: ${({ size }) => size ? `${size.width}rem` : '1.2rem'};
    

    & path {
      transition: all 200ms ease-out;
      fill: ${({ theme }) => theme.color.yetAnotherGrey};
    }

    &.white path {
      fill: ${({ theme }) => theme.color.white};
    }

    &.black path {
      fill: ${({ theme }) => theme.color.black};
    }

    &.primary path {
      fill: ${({ theme }) => theme.color.primary};
    }

    &.gold path {
      fill: ${({ theme }) => theme.color.gold};
    }

    &.green path {
      fill: ${({ theme }) => theme.color.green};
    }

    &.red path {
      fill: ${({ theme }) => theme.color.red};
    }
  }
}
`

export default FaIconContainer
