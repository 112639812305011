import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { IGeolocationProvider } from '../../domain/providers'
import { IAddressResult } from '../../domain/providers/IGeolocationProvider'

const ADDRESS_API_ENDPOINT = 'https://api-adresse.data.gouv.fr'

class GeolocationProvider implements IGeolocationProvider {
  public httpClient: AxiosInstance

  constructor() {
    const config: AxiosRequestConfig = {
      baseURL: ADDRESS_API_ENDPOINT,
      headers: {
        'content-type': 'application/json'
      }
    }
    this.httpClient = axios.create(config)
  }

  async searchAddress(query: string): Promise<Array<IAddressResult>> {
    const result: Array<IAddressResult> = []
    const { data } = await this.httpClient.get('/search', {
      params: {
        q: query,
        type: 'municipality'
      }
    })

    for (const entry of data.features) {
      const address: IAddressResult = {
        longitude: entry.geometry.coordinates[0],
        latitude: entry.geometry.coordinates[1],
        cityCode: entry.properties.postcode,
        city: entry.properties.city,
        country: 'FRA'
      }
      result.push(address)
    }
    return result
  }
}


export default GeolocationProvider
