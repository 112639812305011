import { OfferStatus } from './OfferStatus'
import OfferAction from './OfferAction'

export interface IOfferProposal {
  user: {
    id: string
    username: string
  }
  game: {
    id: string
    gameId: string
    name: string
    platform: string
  }
  wish: {
    id: string
    gameId: string
    name: string
    platform: string
  }
}

export interface IOfferProps {
  id: string
  date: Date
  status: OfferStatus
  actions?: Array<OfferAction>
  proposals: Array<IOfferProposal>
  distance?: number
}

class Offer {
  public readonly id: string
  public readonly date: Date
  public readonly status: OfferStatus
  public readonly users: Array<string>
  public readonly games: Array<string>
  public readonly wishes: Array<string>
  public readonly actions: Array<OfferAction>
  public readonly proposals: Array<IOfferProposal>
  public readonly distance?: number

  constructor({
    id,
    status,
    actions,
    proposals,
    date,
    distance
  }: IOfferProps) {
    this.id = id
    this.date = date
    this.status = status
    this.actions = actions || []
    this.proposals = proposals || []
    this.distance = distance
    this.users = [proposals[0].user.id, proposals[1].user.id]
    this.games = [proposals[0].game.id, proposals[1].game.id]
    this.wishes = [proposals[0].wish.id, proposals[1].wish.id]
  }

  getUserProposal(userId: string): IOfferProposal {
    if (this.proposals[0].user.id === userId) {
      return this.proposals[0]
    }
    return this.proposals[1]
  }

  getCounterProposal(userId: string): IOfferProposal {
    if (this.proposals[0].user.id === userId) {
      return this.proposals[1]
    }
    return this.proposals[0]
  }
}

export default Offer
