enum GamePlatform {
  PC = 'PC',
  OTHER_PLATFORM = 'OTHER_PLATFORM',
  GAMEBOY_COLOR = 'GAMEBOY_COLOR',
  NINTENDO_DS = 'NINTENDO_DS',
  NINTENDO_3DS = 'NINTENDO_3DS',
  GAMECUBE = 'GAMECUBE',
  WII = 'WII',
  WII_U = 'WII_U',
  SWITCH = 'SWITCH',
  PS2 = 'PS2',
  PS3 = 'PS3',
  PS4 = 'PS4',
  PS5 = 'PS5',
  XBOX = 'XBOX',
  XBOX_360 = 'XBOX_360',
  XBOX_ONE = 'XBOX_ONE',
  XBOX_SERIES = 'XBOX_SERIES'
}


const parseGamePlatform = (value: string): GamePlatform => {
  switch (value) {
    case 'WII':
      return GamePlatform.WII
    case 'PC':
      return GamePlatform.PC
    case 'PS2':
      return GamePlatform.PS2
    case 'PS3':
      return GamePlatform.PS3
    case 'XBOX':
      return GamePlatform.XBOX
    case 'XBOX_360':
      return GamePlatform.XBOX_360
    case 'NINTENDO_DS':
      return GamePlatform.NINTENDO_DS
    case 'GAMECUBE':
      return GamePlatform.GAMECUBE
    case 'NINTENDO_3DS':
      return GamePlatform.NINTENDO_3DS
    case 'WII_U':
      return GamePlatform.WII_U
    case 'PS4':
      return GamePlatform.PS4
    case 'SWITCH':
      return GamePlatform.SWITCH
    case 'XBOX_ONE':
      return GamePlatform.XBOX_ONE
    case 'PS5':
      return GamePlatform.PS5
    case 'XBOX_SERIES':
      return GamePlatform.XBOX_SERIES
    default:
      return GamePlatform.OTHER_PLATFORM
  }
}

const isGamePlatform = (value: string): value is GamePlatform => {
  return Object.values(GamePlatform).includes(value as GamePlatform)
}


export {
  GamePlatform,
  isGamePlatform,
  parseGamePlatform
}
