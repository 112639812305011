import { GamePlatform } from './GamePlatform'

export interface IGameResultProps {
  externalId: string
  name: string
  platforms: Array<GamePlatform>
  releaseDate?: Date
  summary?: string
  coverUrl?: string
}


class GameResult {
  externalId: string
  name: string
  platforms: Array<GamePlatform>
  releaseDate?: Date
  summary?: string
  coverUrl?: string

  constructor({ externalId, name, platforms, releaseDate, summary, coverUrl }: IGameResultProps) {
    this.externalId = externalId
    this.name = name
    this.platforms = platforms
    this.releaseDate = releaseDate
    this.summary = summary
    this.coverUrl = coverUrl
  }

}

export default GameResult
